import React from 'react';
import { createTheme, ThemeProvider, Typography } from '@mui/material';
import "@fontsource/poppins/500.css";
import "@fontsource/source-sans-3";

// Create a theme instance
const theme = createTheme({

  palette: {
    mode: 'light',
    
    primary: {
      main: "#49454F"
    },
    secondary: {
      main: "#FFFFFF"
    },


  },
  typography: {
    h1: {
      fontSize: '3.75rem',
      fontFamily: "Poppins",
      fontWeight: 500,
      color: 'black',
    },
    body1: {
      fontFamily: "Source Sans 3",
      fontWeight: 400,
      color: "black",
      fontSize: "1.875rem"
    }
  },
});

export default theme