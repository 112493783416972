import { MathfieldElement } from "mathlive"

export interface Message {
  role: string,
  content: string,
  isGif: boolean
}

export interface DigitMessage {
  conversation_id: string
  created_at: string
  document_url?: string
  id: string
  role: string
  text: string
  user_id: string
  gif: boolean
}

export interface CreateConversationResponse {
  messages: DigitMessage[]
}

export interface MessageResponse {
  messages: Message[]
}



export interface FilePreview {
  name: string;
  url: string;
  type: string;
}

export interface GetThreadsResponse {
  userId: string
  threads: Thread[]
}

export interface Conversation {
  id: string
  created_at: string
  user_id: string
  name: string

}


export interface Thread {
  id: string
  created_at: string
  thread_id: string
  user_id: string
  name: string

}

export interface DeleteConversationResponse {
  success: boolean;
  message: string
}

export interface ThreadResponse {
  threadID: string,
  runID: string,
  runStatus: RunStatus
}

export interface ThreadMessage {
  text: string,
  role: string
  threadID: string
  file_ids: string[],
  image_file: string,
  created_at: string,
  metadata: any

}

export enum RunStatus {
  Queued = "queued",
  InProgress = "in_progress",
  RequiresAction = "requires_action",
  Cancelling = "cancelling",
  Cancelled = "cancelled",
  Failed = "failed",
  Completed = "completed",
  Expired = "expired",
  Error = "error"
}


