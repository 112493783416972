import { FunctionComponent, useEffect, useRef, useState } from "react";
import './LandingPage.css';
import "@fontsource/poppins/500.css";
import { AppBar, Box, Typography } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import theme from "./theme";
import { ReactComponent as DigitIcon } from "../../assets/DigitLogo.svg"
import { ReactComponent as DigitRobot } from "../../assets/digit_illustration_2.svg";
import { Button } from "../../components/CustomMUIComponents";
import VideoBackgroundSvg from "../../assets/video-background.svg"
import ReactPlayer from "react-player";
import BlueGridBackground from "../../assets/blue_grid_background.svg"
import PurpleGridBackground from "../../assets/light_purple_background.svg"
import BlueGridBackgroundMobile from "../../assets/blue_grid_background_mobile.svg"
import { ReactComponent as UploadPDFsImage } from "../../assets/upload_pdfs_image.svg"
import { ReactComponent as StepByStepTipsImage } from "../../assets/step_by_step_tips.svg"
import { ReactComponent as PersonalizedFeedbackImage } from "../../assets/personalized_feedback.svg"
import { ReactComponent as AskQuestionsGetAnswersImage } from "../../assets/ask_questions_get_answers.svg"
import { ReactComponent as ConceptMasteryImage } from "../../assets/concept_mastery.svg"
import { ReactComponent as NoMoreLateNightHomeworkImage } from "../../assets/no_more_homework_battles.svg"
import { ReactComponent as ParentInformedImage } from "../../assets/parent_informed.svg"
import { ReactComponent as ParentInformedMobileImage } from "../../assets/parent_informed_mobile.svg"
import ParentInformedGraphic from "../../assets/Group228.png";
import { useNavigate } from 'react-router-dom';
import WaitlistSignupForm from "../../components/WaitlistForm";

interface LandingPageProps {

}

const LandingPage: FunctionComponent<LandingPageProps> = () => {
  const navigate = useNavigate()

  const waitlistFormRef = useRef<HTMLDivElement>(null);

  const handleWaitlistClick = () => {
    if (waitlistFormRef.current) {
      waitlistFormRef.current.scrollIntoView({ behavior: "smooth" });
      waitlistFormRef.current.focus();
    }

  }

  const handleLoginClick = () => {
    navigate("login");
  }

  const isMobileScreen = (): boolean => {
    return window.innerWidth <= 768; // We can adjust the threshold value as needed
  };
  const [isMobile, setIsMobile] = useState<boolean>(isMobileScreen());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileScreen());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);





  return (
    <ThemeProvider theme={theme}>
      <Box data-testid="landing-page" width={"100%"} sx={{ backgroundColor: "#FFF" }} display="flex" flexDirection="column" justifyContent="space-evenly" >

        <AppBar position="static" elevation={0} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: "#EFEDFF", height: "auto", width: "100%" }}>
          <DigitIcon style={{

            color: '#1232A0',
            marginTop: "0.75rem",
            marginBottom: "0.75rem",
            marginLeft: '2rem',
            alignSelf: "center",
            fontFamily: 'Poppins',
          }} />

          {/* <Button uiMode="light" variant="outlined" sx={{ width: "auto", my: "1rem", mx: "2rem" }} onClick={ () => navigate("login")}>Sign In</Button> */}

        </AppBar>

        <Box sx={{ zIndex: "1", backgroundColor: "#523AEC", borderBottomLeftRadius: "50px", borderBottomRightRadius: "50px" }}>
          <Box paddingX={!isMobile ? "min(15rem, 26rem)" : "1rem"} width={"100%"} minHeight={"30.375rem"} display={"flex"} alignSelf={"center"} flexDirection={isMobile ? "column-reverse" : "row"} justifyContent={"center"}>

            <Box pt={!isMobile ? "8rem" : "0rem"} pb={!isMobile ? "8rem" : "3rem"} className="tagline-text" display="flex" flexDirection={"column"} >

              <Typography sx={{ color: "white", my: "1.8rem" }} variant="h1">Digit</Typography>

              <Typography sx={{ color: "white", }} variant="body1">Your personal AI math tutor for Utah Common Core!</Typography>

              <Box display={"flex"} alignSelf={isMobile ? "center" : "flex-start"}>
                <Button uiMode="light" variant="contained" sx={{ width: "auto", mt: "2rem", }} onClick={handleLoginClick}>Login</Button>
              </Box>

            </Box>

            <Box className="robot-image-container" display="flex" justifyContent={"center"}>
              <DigitRobot></DigitRobot>

            </Box>



          </Box>



        </Box>





        <Box className="video-demo-section" sx={{ zIndex: "0", transform: "translateY(-5rem)", backgroundImage: `url(${PurpleGridBackground})`, backgroundSize: "cover", backgroundRepeat: 'no-repeat', border: "1px solid black" }} display="flex" flexDirection="column" alignItems="center" justifyContent="center">

          <Box paddingTop={"3.5rem"}>
            <Typography sx={{ color: "black" }} variant="h1">How it Works</Typography>
          </Box>


          <Box sx={{
            marginY: "1.5rem",
            position: 'relative',
            width: '100%', // Set to use full width of the parent container

            transform: 'translate(0.25%, 2%)',
            backgroundColor: `#FF843E`,
            backgroundSize: "cover",
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            border: "solid 2px black"
          }}>
            <Box sx={{
              position: 'relative',
              width: '100%', // Set to use full width of the parent container
              paddingTop: '63.7%', // This sets the height in relation to the width (16:9 aspect ratio)
              backgroundColor: `#CCEA78`,
              backgroundSize: "cover",
              transform: 'translate(-1%, -2%)',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              border: "solid 2px black"
            }}>

              {/* <ReactPlayer url={"https://www.youtube.com/embed/NpEaa2P7qZI"} controls
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: "30px"
              

              }} width={"96%"} height={"94%"}/> */}
              <iframe style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '96%', // Percentage of the parent container width
                height: '94%', // Percentage of the parent container height


              }} src="https://www.youtube.com/embed/9r4el7tqZrc?si=Hau6k_5V2vywPUxz"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen>

              </iframe>



            </Box>
          </Box>



        </Box>

        {/* 
        <Box gap={isMobile ? "2rem" : "5rem"} paddingX={!isMobile ? "min(15rem, 30rem)" : "1rem"} mb={!isMobile ? "8rem" : "2rem"} mt={!isMobile ? "8rem" : "2rem"} width={"100%"} minHeight={"30.375rem"} display={"flex"} alignSelf={"center"} flexDirection={isMobile ? "column" : "row"} justifyContent={"space-between"}>

          <Box className="feature-highlights-text" display="flex" flexDirection={"column"} >

            <Typography sx={{ my: "1.8rem" }} variant="h1">UPLOAD PDFS</Typography>

            <Typography variant="body1">Upload your worksheets and get specific help on your math equations.</Typography>


          </Box>

          <Box mt={isMobile ? "-9rem" : ""} display="flex" justifyContent={"center"}>
            <UploadPDFsImage></UploadPDFsImage>

          </Box>

        </Box> */}


        {/* <Box gap={isMobile ? "2rem" : "5rem"} paddingX={!isMobile ? "min(12rem, 22rem)" : "1rem"} mb={!isMobile ? "8rem" : "2rem"} mt={!isMobile ? "2rem" : "-6rem"} width={"100%"} minHeight={"30.375rem"} display={"flex"} alignSelf={"center"} flexDirection={isMobile ? "column-reverse" : "row"} justifyContent={"space-between"}>


          <Box mt={isMobile ? "-8rem" : ""} display="flex" justifyContent={"center"}>
            <StepByStepTipsImage></StepByStepTipsImage>

          </Box>

          <Box className="feature-highlights-text" sx={{ mr: isMobile ? "" : "5rem" }} display="flex" flexDirection={"column"} >

            <Typography sx={{ my: "1.8rem" }} variant="h1">STEP-BY-STEP TIPS</Typography>

            <Typography variant="body1">Digit walks you through your math equations so you can focus on learning without the stress.</Typography>


          </Box>

        </Box> */}
        {/* 
        <Box gap={isMobile ? "3.5rem" : "5rem"} paddingX={!isMobile ? "min(15rem, 25rem)" : "1rem"} mb={!isMobile ? "8rem" : "2rem"} mt={!isMobile ? "2rem" : "-5rem"} width={"100%"} minHeight={"30.375rem"} display={"flex"} alignSelf={"center"} flexDirection={isMobile ? "column" : "row"} justifyContent={"space-between"}>

          <Box className="feature-highlights-text" display="flex" flexDirection={"column"} >

            <Typography sx={{ my: "1.8rem" }} variant="h1">PERSONALIZED FEEDBACK</Typography>

            <Typography variant="body1">No more tears and frustration-- Digit explains things the way you learn best.</Typography>


          </Box>

          <Box mt={isMobile ? "-9rem" : ""} display="flex" justifyContent={"center"}>
            <PersonalizedFeedbackImage></PersonalizedFeedbackImage>

          </Box> */}

        {/* </Box> */}

        {/* <Box width={"auto"} display={"flex"} alignSelf={"center"} flexDirection={isMobile ? "column" : "row"} justifyContent={"center"}>
          <Button variant="contained" onClick={() => navigate("sign-up")}>Sign Up</Button>
        </Box> */}

        <Box display={"flex"} justifyContent={"center"} textAlign={"center"} className="tagline-text" flexDirection={"column"}>

          <Typography sx={{ color: "black", mt: "1.8rem", mb: "1rem" }} variant="h1">Designed for Students</Typography>
          <Box display={"flex"} flexDirection={isMobile ? "column" : "row"} justifyContent={isMobile ? "center" : "space-evenly"}
            sx={{
              maxWidth: isMobile ? "100%" : "90%",
              alignSelf: "center",
              height: "100%",
              marginBottom: "4rem",
            }}>

            <Box flex={isMobile ? "1 1 auto" : "0 1 auto"} sx={{
              width: "100%",
              padding: "2rem",
              height: "auto",

            }}>
              <AskQuestionsGetAnswersImage style={{ width: "100%", height: "100%" }}></AskQuestionsGetAnswersImage>
            </Box>

            <Box flex={isMobile ? "1 1 auto" : "0 1 auto"} sx={{
              width: "100%",
              padding: "2rem",
              height: "auto",
            }}>
              <ConceptMasteryImage style={{ width: "100%", height: "100%" }}></ConceptMasteryImage>

            </Box>

            <Box flex={isMobile ? "1 1 auto" : "0 1 auto"} sx={{
              width: "100%",
              padding: "2rem",
              height: "auto",

            }}>
              <NoMoreLateNightHomeworkImage style={{ width: "100%", height: "100%" }}></NoMoreLateNightHomeworkImage>
            </Box>
          </Box>
        </Box>


        <Box marginX={isMobile ? "1rem" : "4rem"} marginBottom={"4rem"} display={"flex"} justifyContent={"center"}>
          {isMobile ? <ParentInformedMobileImage style={{ width: "90%", height: "90%" }}></ParentInformedMobileImage> : <ParentInformedImage style={{ width: "90%", height: "90%" }}></ParentInformedImage>}
        </Box>


        {/* <Box marginX={"auto"} marginY={"4rem"} display={"flex"} justifyContent="center" alignItems="center" maxWidth={isMobile ? "80%" : "50%"} >
          <WaitlistSignupForm ref={waitlistFormRef}></WaitlistSignupForm>
        </Box> */}


        <Box width={"100%"} mt={isMobile ? "8rem" : "10rem"} height={isMobile ? "4rem" : "6rem"} sx={{ backgroundColor: "#EFEDFF", borderTop: "solid black 1px" }}>

        </Box>

      </Box>

    </ThemeProvider>

  );
}


export default LandingPage;