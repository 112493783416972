import React, { useEffect, useState } from "react"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MoodIcon from '@mui/icons-material/Mood';
import { ThreadMessage } from "../lib/types";
import "./styles/ChatBubbleStyles.css"
import { convertLatexToHTMLForUser, convertLatexToHTMLForDigit } from "../lib/helper";
import { Gif, Grid } from '@giphy/react-components'
import { GiphyFetch } from '@giphy/js-fetch-api'
import { IGif } from "@giphy/js-types";
import FilePreviewComponent from "./FilePreview";
import DigitImageComponent from "./DigitImageComponent";
import { useMediaQuery } from "react-responsive";



interface ChatBubbleProps {
  message: ThreadMessage;
}

const ChatBubble: React.FC<ChatBubbleProps> = ({ message }) => {
  const giphyAPIKey = process.env.REACT_APP_GIPHY_API_KEY
  const gf = new GiphyFetch(giphyAPIKey || "")
  const [gif, setGif] = useState<IGif | null>(null);
  const isSmallScreen = useMediaQuery({ maxWidth: 600 });

  useEffect(() => {


  }, [])

  return (
    <div>
      {message.role === "user" ? (
        <div>
          {/* This is for the files if there is one*/}
          {message.metadata.attachedFileName &&
            <div className="chat chat-end">
              <div className="chat-image avatar">
                <div className="w-10 rounded-full">
                  <AccountCircleIcon fontSize="large" />
                </div>
              </div>
              {/* {message.mathHTML && <div className="chat-bubble chat-bubble-info" dangerouslySetInnerHTML={{ __html: message.mathHTML }}></div>} */}

              <div className="chat-bubble chat-bubble-primary" style={{ wordBreak: 'break-word' }} >
                <div><FilePreviewComponent filePreview={{
                  name: message.metadata.attachedFileName,
                  type: message.metadata.attachedFileType,
                  url: "none hopefully we add this later"
                }} removePreview={() => { }} isInMessage={true}></FilePreviewComponent> </div>

              </div>

            </div>
          }
          <div className="chat chat-end">
            <div className="chat-image avatar">
              <div className="w-10 rounded-full">
                <AccountCircleIcon fontSize="large" />
              </div>
            </div>
            {/* {message.mathHTML && <div className="chat-bubble chat-bubble-info" dangerouslySetInnerHTML={{ __html: message.mathHTML }}></div>} */}

            <div className="chat-bubble chat-bubble-primary" style={{ wordBreak: 'break-word' }} >
              <div dangerouslySetInnerHTML={{ __html: wrapStringInHTML(message.text, "User") }}></div>
            </div>

          </div>
        </div>
      ) : message.role === "typing" ? (
        <div className="chat chat-start">
          <div className="chat-image avatar">
            <div className="w-10 rounded-full">
              {/* <Icon>
                <img src={DigitIconImage} height={"inherit"} width={"inherit"} alt="digit" />
              </Icon> */}
              <MoodIcon fontSize="large" />
            </div>
          </div>
          {/* {message.mathHTML && <div className="chat-bubble chat-bubble-info" dangerouslySetInnerHTML={{ __html: message.mathHTML }}></div>} */}

          <div className="chat-bubble chat-bubble-secondary"  >
            <TypingIndicator></TypingIndicator>
          </div>

        </div>

      ) : (
        <div>
          {message.image_file !== "" &&
            <div className="chat chat-start">
              <div className="chat-image avatar">
                <div className="w-10 rounded-full">
                  {/* <Icon>
                <img src={DigitIconImage} height={"inherit"} width={"inherit"} alt="digit" />
              </Icon> */}
                  <MoodIcon fontSize="large" />
                </div>
              </div>

              <div style={{
                maxWidth: isSmallScreen ? "auto" : "50%",
              }} className="chat-bubble chat-bubble-secondary">

                <DigitImageComponent base64Image={message.image_file}></DigitImageComponent>
              </div>
            </div>}

          <div className="chat chat-start">
            <div className="chat-image avatar">
              <div className="w-10 rounded-full">
                {/* <Icon>
                <img src={DigitIconImage} height={"inherit"} width={"inherit"} alt="digit" />
              </Icon> */}
                <MoodIcon fontSize="large" />
              </div>
            </div>

            <div className="chat-bubble chat-bubble-secondary">

              <div dangerouslySetInnerHTML={{ __html: wrapStringInHTML(message.text, "Digit") }}></div>
            </div>
          </div>
        </div>
      )
        // <div className="talk-bubble tri-right round btm-left">
        // <div className="talktext">
        //   <p>{message.content}</p>  </div>
        // </div>
      }
    </div>

  )
}

const wrapStringInHTML = (input: string, role: string) => {
  let formattedText;
  switch (role) {
    case "User":
       formattedText = convertLatexToHTMLForUser(input);
      // formattedText = parseAndFormatTextFromDigit(input);
      break;
    case "Digit":
      formattedText = parseAndFormatTextFromDigit(input);
      break;
    default:
      formattedText = input;
      break;
  }

  return `<div>${formattedText}</div>`;
}

function parseAndFormatTextFromDigit(input: string): string {
  const regex = /@+([^@]+)@+/g;
  try {
    let result = input.replace(/\n/g, "<br>");
    result = result.replace(regex, (match, capturedText) => {
      try {
        const formattedText = convertLatexToHTMLForDigit(capturedText);
        return formattedText;
      } catch (e) {
        console.error(`Error while converting LaTeX to HTML: ${e}`);
        return match; // Return the original LaTeX text if conversion fails
      }
    });

    return result;

  } catch (e) {
    console.error(e)
    return input
  }

}

const typingAnimation = (
  <React.Fragment>
    <span className='typing-dot'></span>
    <span className='typing-dot'></span>
    <span className='typing-dot'></span>
  </React.Fragment>
);


export const TypingIndicator = () => {
  return (
    <div id='typing-indicator'>
      {typingAnimation}
    </div>)
}




export default ChatBubble

function useAsync(arg0: () => void, arg1: never[]) {
  throw new Error("Function not implemented.");
}
