import { styled } from "@mui/material/styles";
import MuiButton, { ButtonProps } from "@mui/material/Button";
import MuiTextField, { TextFieldProps } from "@mui/material/TextField"
import "@fontsource/poppins/500.css";

interface DigitButtonProps {
  uiMode?: string
}

export const Button = styled(MuiButton)<ButtonProps & DigitButtonProps>((props) => ({
  backgroundColor: props.variant === 'contained' ? '#CCEA78' : "none",
  borderWidth: "2px",
  borderStyle: "solid",
  // boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);",
  borderColor: (props.variant === "outlined" || props.variant === "contained") && props.uiMode === "light" ? '#000000' : props.variant === "outlined" && props.uiMode === "dark" ? "#FFFFFF" : "#000",
  borderRadius: 50,
  paddingLeft: "2rem",
  paddingRight: "2rem",
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem",
  fontFamily: "Poppins",
  fontSize: "1rem",
  width: "auto",
  textTransform: "capitalize",
  ...(props.variant === "outlined" && {
    color: props.uiMode === "dark" ? "#FFFFFF" : "#000000"

  }),
  ...(props.variant === "contained" && {
    color: "#000"

  }),
  ...(props.variant === "text" && {
    color: "#000000",
    borderWidth: "2px"
  }),
  '&:hover': {
    backgroundColor: props.variant === 'contained' ? '#E0FF88' : "none",
    borderWidth: props.variant === "text" ? "2px" : "2px",
    borderStyle: "solid",
    borderColor: props.variant === 'outlined' && props.uiMode === "light" ? '#000000' : props.variant === 'outlined' && props.uiMode === "dark" ? "#E1D9D1" : "none",
    color: props.variant !== 'contained' && props.uiMode === "light" ? '#000000' : props.variant !== 'contained' && props.uiMode === "dark" ? "#E1D9D1" : "none",
    boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)"

  },
  '& .MuiButton-startIcon': { mr: "0.5rem" },
  '&:disabled' : {
    borderWidth: "2px",
    backgroundColor: "#BED08C"
  }

}));

const StyledDigitTextField = styled(MuiTextField)((props) => ({
  borderRadius: `0.25rem 0.25rem 0rem 0rem`,
  '& fieldset': {
    border: props.color === "primary" ? '1px solid #000000' : '1px solid  #ed6c02',
    borderRadius: `0.25rem 0.25rem 0rem 0rem`,
  },
  '&:focus-within fieldset, &:focus-visible fieldset': {
    border: props.color === "primary" ? '1px solid #000000!important' : '1px solid  #ed6c02!important',
    borderRadius: `0.25rem 0.25rem 0rem 0rem`,
  },
  '&:hover fieldset': {
    border: props.color === "primary" ? '1px solid #000000!important' : '1px solid  #ed6c02!important',
    borderRadius: `0.25rem 0.25rem 0rem 0rem`,
  },

}));


type DigitTextFieldProps = TextFieldProps & {
  isInputValid?: boolean;
};


export const DigitTextField: React.FC<DigitTextFieldProps> = ({ isInputValid, ...props }) => {

  return (<StyledDigitTextField {...props}
    color={isInputValid ? "primary" : "warning"}
    InputLabelProps={{
      sx: {
        color: isInputValid ? "#49454F" : "#ed6c02",
      },
    }}
    inputProps={{
      sx: {
        color: isInputValid ? "#49454F" : "#ed6c02",
      },
    }}


  />);
};


