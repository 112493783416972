import { FunctionComponent, useEffect, useState } from "react";
import { FilePreview } from "../lib/types";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';


interface FilePreviewComponentProps {
  filePreview: FilePreview
  removePreview: () => void
  isInMessage?: boolean
}

const FilePreviewComponent: FunctionComponent<FilePreviewComponentProps> = ({ filePreview, removePreview, isInMessage = false }) => {




  const isImageFile = (fileType: string): boolean => {
    const acceptedImageTypes = ['image/png', 'image/jpeg', 'image/jpg'];

    return acceptedImageTypes.includes(fileType)

  }





  return (
    <div style={{
      marginLeft: isInMessage ? "0rem" : "2rem",
      display: "flex",
      justifyContent: isInMessage ? "center" : "flex-start",
      alignItems: isInMessage ? "center" : "flex-start",
      flexDirection: "column",
      height: "auto"
    }}>

      <div style={{
        position: 'relative',
        width: '75px', // Thumbnail width
        height: '75px', // Thumbnail height
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0.5rem',
      }}>
        <div style={{
          alignSelf: 'center',
        }}>
          {isImageFile(filePreview.type) ? <img src={filePreview.url} alt={filePreview.name} style={{
            maxWidth: '100%',
            maxHeight: '100%',
            display: 'block',
          }} /> : filePreview.type === "application/pdf" ? <><PictureAsPdfIcon fontSize="large"></PictureAsPdfIcon></>
            : filePreview.type === "text/plain" || filePreview.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? <><DescriptionIcon fontSize="large"></DescriptionIcon></>
              : <>invalid file type</>}

        </div>
        

        {!isInMessage &&
        <button onClick={removePreview} style={{
          position: 'absolute',
          top: '0',
          right: '0',
          background: '#ff4545', // Red background color for the remove button
          color: 'white',
          border: 'none',
          borderRadius: '50%',
          width: '20px',
          height: '20px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '12px',
        }}>
          X
        </button>}
      </div>
      <p style={{ fontSize: "0.6rem", position: "relative"}}>{filePreview.name}</p>

    </div>

  );

}

export default FilePreviewComponent;