import * as React from 'react';
import { Button, DigitTextField } from '../components/CustomMUIComponents';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppBar, Container, IconButton, InputAdornment } from '@mui/material';
import { useEffect, useState } from 'react';
import { supabase } from '../lib/supabaseClient';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DigitIcon } from "../assets/DigitLogo.svg"
import "@fontsource/source-sans-pro"
import ErrorIcon from '@mui/icons-material/Error';
import "./styles/MuiStylesOverride.css"


export default function ForgotPassword() {
  const [email, setEmail] = useState("")
  const navigate = useNavigate()
  const [emailIsValid, setEmailIsValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState("")
  const [requestSent, setRequestSent] = useState(false);

  const isFormValid = (): boolean => {
    if (email === "" || !emailIsValid) {
      setEmailIsValid(false)
    }
    return (emailIsValid)

  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();


      if (isFormValid()) {
          const { data, error } = await supabase.auth.resetPasswordForEmail(email);

          if (error) {
              // TODO handle error messages from supabase
              switch (error.message) {
              default:
                  // Handle other errors
                  console.error('An error occurred:', error.message);
                  setErrorMessage(`${error.message}`)
              }
              return null
          } else {
              setRequestSent(true);
          }
          setErrorMessage("")
      };
  }

  useEffect(() => {
    if (email === "") {
      setEmailIsValid(true)
    } else {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const isValid = re.test(String(email).toLowerCase());

      setEmailIsValid(isValid)

    }
  }, [email])

  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: "#49454F"
      },
      secondary: {
        main: "#FFFFFF"
      },
    },
  })

  return (
    <Box gap={"2rem"} style={{ backgroundColor: "#FFFFFF" }} display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" >
      <AppBar elevation={0} position="static" sx={{ backgroundColor: "white" }}>
        <DigitIcon  onClick={() => navigate("../")} style={{
          flexGrow: 1,
          color: '#1232A0',
          marginTop: "0.5rem",
          marginLeft: '2rem',
          fontFamily: 'Poppins',
        }} />
      </AppBar>

      <Box width="100%" sx={{ paddingTop: "7rem", paddingBottom: "2rem", paddingLeft: "1rem", paddingRight: "1rem" }}>
        <ThemeProvider theme={defaultTheme}>
          <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box
              width="100%"
              gap="1rem"
              sx={{
                marginTop: "1rem",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: "center",
              }}
            >
              <Typography color="primary" component="h2" variant="h5" fontSize={"2.8125rem;"} fontFamily={"Poppins"} style={{ textAlign: "center" }}>
                Recover Your Password
              </Typography>
              <Typography color="primary" component="body" variant="h5" fontSize={" 1.25rem;"} fontFamily={"Source Sans Pro"} style={{ textAlign: "center" }}>
                If an account is registered with your email, we will send a password recovery link to your email to create a new password.
              </Typography>
              <Typography style={{color: "#ed6c02"}} component="h1" variant="h5" fontSize={"1rem;"} fontFamily={"Source Sans Pro"}>
                {errorMessage !== "" && <IconButton edge="end" sx={{ mr: "0.25rem" }}>
                  <ErrorIcon color='warning' />

                </IconButton>}{errorMessage}
              </Typography>
              <Box autoComplete="off" gap="1rem" display="flex" flexDirection="row" alignItems="start" component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 2, width: '100%' }}>
                <DigitTextField
                  isInputValid={emailIsValid}
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  
                  helperText={!emailIsValid && "Please enter a valid email address."}
                  autoComplete="email"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value)
                  }}
                  InputProps={{
                    endAdornment: !emailIsValid && <InputAdornment position='end'>
                      <IconButton edge="end">
                        <ErrorIcon color='warning' />
                      </IconButton>
                    </InputAdornment>
                  }}
                />
                <Button
                    type="submit"
                    variant="contained"
                    sx={{ height: "3.25rem", width: "13rem", fontSize: "1.1rem" }}
                    disabled={requestSent}
                >
                Send
                </Button>
              </Box>
              {requestSent && <Typography>Your request has been sent!</Typography>}
            </Box>
          </Container>
        </ThemeProvider>
      </Box>
    </Box>
  );
}