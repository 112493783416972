import * as React from 'react';
import "@fontsource/poppins/600.css";
import "@fontsource/source-sans-pro"
import { Button } from './CustomMUIComponents';
import CssBaseline from '@mui/material/CssBaseline';
import { DigitTextField } from './CustomMUIComponents';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { IconButton, InputAdornment } from '@mui/material';
import { useEffect, useState } from 'react';
import { supabase } from '../lib/supabaseClient';
import { Link, useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#49454F"
    },
    secondary: {
      main: "#FFFFFF"
    },

  },
})

export default function SignInComponent() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [isEmailValid, setEmailIsValid] = useState(true)
  const [isPasswordValid, setPasswordIsValid] = useState(true)
  const [badLogin, setBadLogin] = useState(false)
  const navigate = useNavigate()



  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (formIsValid()) {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: email,
        password: password,
      })

      if (error) {
        // bad login
        setBadLogin(true)
        setEmailIsValid(false)
        setPasswordIsValid(false)
        return null
      }


      navigate("../digit", { replace: false })

    } else {
      setBadLogin(true)
      setEmailIsValid(false)
      setPasswordIsValid(false)

    }



  };

  const handleClickShowPassword = () => {

    setShowPassword(!showPassword)
  }

  const formIsValid = (): boolean => {
    if (!isPasswordValid || password === "") return false
    if (!isEmailValid || email === "") return false
    return true
  }

  useEffect(() => {
    if (email === "") {
      setEmailIsValid(true)
    } else {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const isValid = re.test(String(email).toLowerCase());

      setEmailIsValid(isValid)
      if (isValid) {
        setBadLogin(false)
      }

    }
  }, [email])



  return (


    <Box >
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box justifyContent={"center"}
            sx={{
              marginTop: "8rem",
              gap: "1rem",
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',

            }}
          >
            <Box width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"} >
              <Typography color="primary" component="h1" variant="h5" fontSize={" 4.375rem;"} fontFamily={"Poppins"}>
                Sign In
              </Typography>

            </Box>

            <Box width="100%" display="flex" alignItems={"center"} flexDirection={"column"} component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <Box width="100%" display={"flex"} flexDirection={"column"}>
                <DigitTextField
                  isInputValid={isEmailValid}
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value)
                  }}

                  InputProps={{
                    endAdornment: !isEmailValid && <InputAdornment position='end'>
                      <IconButton edge="end">
                        <ErrorIcon color='warning' />

                      </IconButton>

                    </InputAdornment>
                  }}
                />
                <DigitTextField
                  isInputValid={isPasswordValid}
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="current-password"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setPasswordIsValid(true)
                    setBadLogin(false)
                    setPassword(event.target.value)
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleClickShowPassword}
                        edge="end">
                        <div >
                          {!isPasswordValid ? <ErrorIcon color='warning' sx={{ mr: "0.25rem" }} /> :
                            showPassword ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}

                        </div>
                      </IconButton>

                    </InputAdornment>
                  }}
                />
              </Box>
              <Link to="../forgot-password" style={forgotPasswordStyle}>
                {"Forgot Password?"}
              </Link>

              <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                {badLogin && <Typography fontFamily={"Source Sans Pro"} sx={{ color: "#ed6c02" }} >Invalid Email and/or password</Typography>}
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 3, mb: 2,
                    width: "auto",
                    fontSize: "1.25rem",
                    fontFamily: "Poppins",
                    height: "3.0625rem",
                    padding: "0rem 1.5rem",
                  }}
                >
                  Sign In
                </Button>

                <Link to="../sign-up" style={linkStyle}>
                  {"Don't have an account? Sign Up"}
                </Link>


              </Box>


            </Box>
          </Box>
          {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
        </Container>
      </ThemeProvider>
    </Box>
  );
}


const linkStyle = {
  margin: "1rem",
  textDecoration: "none",
  color: '#462ED8',
  fontSize: "0.9375rem",
  fontFamily: "Source Sans Pro"
};

const forgotPasswordStyle = {
  marginTop: "0.1rem",
  marginBottom: "1rem",
  textDecoration: "none",
  color: '#462ED8',
  fontSize: "0.9375rem",
  fontFamily: "Source Sans Pro",
  alignSelf: "flex-start"
}