import * as React from 'react';
import { Button, DigitTextField } from '../components/CustomMUIComponents';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppBar, Container, IconButton, InputAdornment } from '@mui/material';
import { useEffect, useState } from 'react';
import { supabase } from '../lib/supabaseClient';
import { Link, useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ReactComponent as DigitIcon } from "../assets/DigitLogo.svg"
import "@fontsource/source-sans-pro"
import ErrorIcon from '@mui/icons-material/Error';
import "./styles/MuiStylesOverride.css"


export default function SignUp() {

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const navigate = useNavigate()
  const [confirming, setConfirming] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [birthday, setBirthday] = useState("")
  const [age, setAge] = useState<number>();
  const [errorMessage, setErrorMessage] = useState("")
  const [firstNameValid, setFirstNameValid] = useState(true);
  const [lastNameValid, setLastNameValid] = useState(true);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [birthdayIsValid, setBirthdayIsValid] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {

  }, [confirming])

  const isFormValid = (): boolean => {
    setFirstNameValid(firstName !== "");
    setLastNameValid(lastName !== "");
    setEmailIsValid(email !== "" && emailIsValid);
    setPasswordsMatch(password !== "" && password === confirmPassword);
    setBirthdayIsValid(birthday !== "" && birthdayIsValid && age !== undefined && birthday.length === 10);

    return (firstNameValid && lastNameValid && emailIsValid && passwordsMatch && birthdayIsValid);
  }


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormSubmitted(true)

    if (isFormValid()) {
      const { data, error } = await supabase.auth.signUp({
        email: email,
        password: password,
        options: {
          data: {
            first_name: firstName,
            last_name: lastName,
            avatar_url: "N/A",
            birthday: birthday,
            age: age
          }
        }
      })

      if (error) {
        // bad login

        // TODO handle error messages from supabase, user exists etc...
        switch (error.message) {
          case 'A user with this email address already exists.':
            // Handle "user already exists" error
            console.error('User already exists');
            setErrorMessage("A user with this email address already exists.")
            break;
          case 'Password must be at least 8 characters long.':
            // Handle "password too short" error
            console.error('Password is too short');
            setErrorMessage("Password must be at least 8 characters long.")
            break;
          default:
            // Handle other errors
            console.error('An error occurred:', error.message);
            setErrorMessage(`${error.message}`)
        }
        return null
      }
      setErrorMessage("")


      if (data.session) {
        navigate("../digit", { replace: false })
      } else if (data.user) {
        setConfirming(true)
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }


  useEffect(() => {
    if (formSubmitted) {
      setPasswordsMatch(password === confirmPassword);
    }
  }, [password, confirmPassword, formSubmitted]);

  useEffect(() => {
    if (formSubmitted) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      setEmailIsValid(re.test(String(email).toLowerCase()));
    }
  }, [email, formSubmitted]);

  const handleBirthdayChange = (dateString: string) => {

    // Remove all non-digit characters
    const numbers = dateString.replace(/[^\d]/g, '');
  
    // Limit the input to 8 digits (MMDDYYYY)
    const trimmed = numbers.slice(0, 8);
  
    // Add slashes after the month (2 digits) and day (2 more digits)
    let formattedInput = trimmed;
    if (trimmed.length > 4) {
      formattedInput = `${trimmed.slice(0, 2)}/${trimmed.slice(2, 4)}/${trimmed.slice(4)}`;
    } else if (trimmed.length > 2) {
      formattedInput = `${trimmed.slice(0, 2)}/${trimmed.slice(2)}`;
    }
  
    // Set the state with the formatted input
    setBirthday(formattedInput);

    if (!formSubmitted) {
      return;
    }

    // Handle the case where the user deletes characters after being valid
    if (dateString.length < 10) {
      setBirthdayIsValid(false);
      return; 
    }

    // Continue with your validation logic
    if (isValidDate(formattedInput)) {
      setBirthdayIsValid(true);
      const date = stringToDate(formattedInput);
      const calculatedAge = calculateAge(date);
      setAge(calculatedAge);
    } else {
      setBirthdayIsValid(false);
    }
  }

  const isValidDate = (dateString: string) => {
    const parts = dateString.split("/");
    if (parts.length !== 3) return false;
  
    const day = parseInt(parts[1], 10);
    const month = parseInt(parts[0], 10);
    const year = parseInt(parts[2], 10);
  
    if (month < 1 || month > 12 || day < 1 || year < 1900 || year > new Date().getFullYear()) return false;
  
    const date = new Date(year, month - 1, day);
    return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
  };

  const stringToDate = (dateString: string) => {
    const parts = dateString.split("/");
    const month = parseInt(parts[0], 10) - 1;
    const day = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    return new Date(year, month, day);
  }

  const calculateAge = (birthDate: Date) => {
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };



  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: "#49454F"
      },
      secondary: {
        main: "#FFFFFF"
      },

    },
  })

  return (
    <Box gap={"2rem"} style={{ backgroundColor: "#FFFFFF" }} display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" >
      <AppBar elevation={0} position="static" sx={{ backgroundColor: "white" }}>
        <DigitIcon  onClick={() => navigate("../")} style={{
          flexGrow: 1,
          color: '#1232A0',
          marginTop: "0.5rem",
          marginLeft: '2rem',
          fontFamily: 'Poppins',
        }} />
      </AppBar>

      <Box width="100%" sx={{ paddingBottom: "2rem", paddingLeft: "1rem", paddingRight: "1rem" }}>
        <ThemeProvider theme={defaultTheme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              width="100%"
              gap="1rem"
              sx={{
                marginTop: "1rem",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: "center",
              }}
            >
              <Typography color="primary" component="h1" variant="h5" fontSize={" 4.375rem;"} fontFamily={"Poppins"}>
                Sign up
              </Typography>
              <Typography style={{color: "#ed6c02"}} component="h1" variant="h5" fontSize={"1rem;"} fontFamily={"Source Sans Pro"}>
                {errorMessage !== "" && <IconButton edge="end" sx={{ mr: "0.25rem" }}>
                  <ErrorIcon color='warning' />

                </IconButton>}{errorMessage}
              </Typography>
              <Box autoComplete="off" gap="1.63rem" display="flex" flexDirection="column" alignItems="start" component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 2, width: '100%' }}>

                <Box display="flex" flexDirection="row" gap="1rem">
                  <DigitTextField
                    isInputValid={firstNameValid}
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    autoComplete="given-name"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (formSubmitted && event.target.value !== "") {
                        setFirstNameValid(true)
                      }
                      setFirstName(event.target.value)
                    }}
                    InputProps={{
                      endAdornment: !firstNameValid && <InputAdornment position='end'>
                        <IconButton edge="end">
                          <ErrorIcon color='warning' />

                        </IconButton>

                      </InputAdornment>
                    }}
                  />

                  <DigitTextField
                    isInputValid={lastNameValid}
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (formSubmitted && event.target.value !== "") {
                        setLastNameValid(true)
                      }
                      setLastName(event.target.value)
                    }}
                    InputProps={{
                      endAdornment: !lastNameValid && <InputAdornment position='end'>
                        <IconButton edge="end">
                          <ErrorIcon color='warning' />

                        </IconButton>

                      </InputAdornment>
                    }}
                  />
                </Box>

                <DigitTextField
                  isInputValid={birthdayIsValid}
                  required
                  label="Birthday ex: MM/DD/YYYY"
                  id="birthday"
                  name="birthday"
                  fullWidth
                  value={birthday}
                  helperText={!birthdayIsValid && "Please enter your birthday in the form MM/DD/YYYY"}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleBirthdayChange(event.target.value)
                  }}
                  InputProps={{
                    endAdornment: !birthdayIsValid && <InputAdornment position='end'>
                      <IconButton edge="end">
                        <ErrorIcon color='warning' />

                      </IconButton>

                    </InputAdornment>
                  }}
                />

                <DigitTextField
                  isInputValid={emailIsValid}
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  
                  helperText={!emailIsValid && "Please enter a valid email address."}
                  autoComplete="email"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value);
                    if (formSubmitted) {
                      setEmailIsValid(event.target.value !== "" && emailIsValid);
                    }
                  }}
                  InputProps={{
                    endAdornment: !emailIsValid && <InputAdornment position='end'>
                      <IconButton edge="end">
                        <ErrorIcon color='warning' />

                      </IconButton>

                    </InputAdornment>
                  }}
                />

                <DigitTextField
                  isInputValid={passwordsMatch}
                  required
                  fullWidth
                  name="password"
                  label="Password (at least 8 characters)"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  helperText={!passwordsMatch && "Passwords don't match."}
                  autoComplete="current-password"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setPassword(event.target.value);
                    if (formSubmitted) {
                      setPasswordsMatch(password === event.target.value && confirmPassword !== "");
                    }
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleClickShowPassword}
                        edge="end">
                        <div >
                          {!passwordsMatch ? <ErrorIcon color='warning' sx={{ mr: "0.25rem" }} /> :
                            showPassword ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}

                        </div>
                      </IconButton>
                    </InputAdornment>
                  }}
                />

                <DigitTextField
                  isInputValid={passwordsMatch}
                  required
                  fullWidth
                  name="confirm_password"
                  label="Confirm Password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  autoComplete="current-password"
                  helperText={!passwordsMatch && "Passwords don't match."}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setConfirmPassword(event.target.value);
                    if (formSubmitted) {
                      setPasswordsMatch(password === event.target.value && event.target.value !== "");
                    }
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleClickShowConfirmPassword}
                        edge="end">
                        <div >
                          {!passwordsMatch ? <ErrorIcon color='warning' sx={{ mr: "0.25rem" }} /> :
                            showConfirmPassword ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
                        </div>

                      </IconButton>

                    </InputAdornment>
                  }}
                />

                <Box display="flex" flexDirection="column" alignSelf="center">
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ mt: 3, mb: 2, height: "3.25rem", width: "15rem", fontSize: "1.1rem" }}
                    >
                      Create Account
                    </Button>

                    <Link to="../login" style={linkStyle}>
                      Already have an account? Sign in
                    </Link>

                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </Box>
    </Box>

  );
}


const linkStyle = {
  margin: "1rem",
  textDecoration: "none",
  color: '#462ED8',
  fontSize: "0.9375rem",
  fontFamily: "Source Sans Pro"

};