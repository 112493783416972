import React, { useCallback, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import ChatBubble from './ChatBubble';
import { Manager } from '../lib/manager';
import { Conversation, ThreadResponse, DigitMessage, Message, RunStatus, ThreadMessage, Thread } from '../lib/types';
import { convertAsciiMathToLatex, MathfieldElement } from 'mathlive';
import "./styles/MathFieldStyles.css"
import { ReactComponent as DigitSendIcon } from "../assets/NewDigitSendIcon.svg"
import { ReactComponent as DigitAttachmentIcon } from "../assets/NewDigitAttachmentIcon.svg"
import { ReactComponent as DigitAddEquationIcon } from "../assets/DigitAddEquationIcon.svg"
import { ReactComponent as DigitRemoveIcon } from "../assets/DigitRemoveIcon.svg"
import { ReactComponent as DigitAddAttachmentIcon } from "../assets/DigitAddAttachmentIcon.svg"
import { ReactComponent as DigitAddIcon } from "../assets/DigitAddIcon.svg"
import TextField from '@mui/material/TextField';
import { FilePreview } from '../lib/types'
import FilePreviewComponent from './FilePreview';
import { supabase } from '../lib/supabaseClient';
import { CircularProgress, SpeedDial, SpeedDialAction, SpeedDialIcon, ThemeProvider, createTheme } from "@mui/material";
import { isValid, set } from 'date-fns';
import { isValidFileType } from '../lib/helper';
import { useSnackbar } from './SnackbarContext';
import { threadId } from 'worker_threads';
import "@fontsource/source-sans-pro"
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { keyboard } from '@testing-library/user-event/dist/keyboard';



declare global {
  namespace JSX {
    interface IntrinsicElements {
      'math-field': React.DetailedHTMLProps<React.HTMLAttributes<MathfieldElement>, MathfieldElement>
    }
  }
}

interface ChatDialogFunctionalProps {
  signedIn: boolean
  latex?: string
  question?: string
  numChats?: number
  isVisible?: boolean
}


export interface ChatDialogFunctionalRef {
  resetChatState: () => void;
  loadConversation: (id: string) => void;



}

const ChatDialogFunctional =
  React.forwardRef<ChatDialogFunctionalRef, ChatDialogFunctionalProps & { setThreads: React.Dispatch<React.SetStateAction<Thread[]>> }>(({ signedIn, latex, numChats, setThreads, isVisible = true }, ref) => {
    const [messages, setMessages] = useState<ThreadMessage[]>([]);
    // const [apiMessages, setApiMessages] = useState<Message[]>([]);
    const [inConversation, setInConversation] = useState(false);
    const [loadingResponse, setLoadingResponse] = useState(false);
    const [inputAreaHeight, setInputAreaHeight] = useState(0)
    const [filePreview, setFilePreview] = useState<FilePreview | null>(null)
    const [selectedFile, setSelectedFile] = useState<File | null>(null)
    const [chatIsLoading, setChatIsLoading] = useState(false)
    const [currConversationId, setCurrConversationId] = useState("none")
    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLDivElement>(null)
    const chatInputRef = useRef<HTMLElement>(null)
    const [value, setValue] = useState("");
    const [equationValue, setEquationValue] = useState("");
    const mathFieldRef = useRef<MathfieldElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [currThreadID, setCurrThreadID] = useState("none")
    const { showSnackbar } = useSnackbar();
    const [showMathKeyboard, setShowMathKeyboard] = useState(false)
    const [keyboardCollapsed, setKeyboardCollapsed] = useState(true)
    const manager = new Manager(process.env.REACT_APP_API_BASE_URL)
    let container = document.getElementById("container")
    let containerHeight = container?.offsetHeight
    const [windowSize, setWindowSize] = useState({
      width: container?.offsetWidth,
      height: container?.offsetHeight,
    });
    const [keyboardHeight, setKeyboardHeight] = useState(0)







    const theme = createTheme({
      palette: {
        primary: {
          main: "#E0DCFF"

        },
        secondary: {
          main: "#FFFFFF"
        },
        warning: {
          main: "#FB754D"
        },
        error: {
          main: "#FB754D"
        }
      },
    });



    const isMobileScreen = (): boolean => {
      return window.innerWidth <= 768; // We can adjust the threshold value as needed
    };
    const [isMobile, setIsMobile] = useState<boolean>(isMobileScreen());

    React.useImperativeHandle(ref, () => ({
      resetChatState: resetState,
      loadConversation: fetchMessages,
    }));


    const handleInputEvent = useCallback((evt: Event) => {

      let event = evt as InputEvent


      if (event.inputType === 'insertLineBreak') {
        if (!loadingResponse) {
          handleSendMessage()

        } else {
          event.preventDefault()
        }

      }

    }, [value])





    const handleKeydownEvent = useCallback((ev: KeyboardEvent) => {


      if (ev.key === '\\') {
        ev.preventDefault();
        mathFieldRef.current?.executeCommand(['insert', '\\backslash']);
      } else if (ev.key === 'Escape') ev.preventDefault();
    }, [])

    const fetchMessages = async (threadID: string) => {
      if (threadID !== "none" && threadID !== currThreadID) {
        setCurrThreadID(threadID)
        setChatIsLoading(true)
        let fetchedMessages = await manager.getThreadMessages(threadID)
        setMessages(fetchedMessages.reverse())
        setChatIsLoading(false)
      }
    }


    useEffect(() => {

      // if (currConversationId !== "none") {
      //   setChatIsLoading(true)
      //   fetchMessages(currConversationId).then(fetchedMessages => {
      //     console.log(fetchedMessages)
      //     let viewableMessages: Message[] = fetchedMessages.filter(message => message.role !== "system")
      //       .map(({ text, role, gif }) => ({ content: text, role: role, isGif: gif }))


      //     setMessages(viewableMessages)

      //   }).finally(() => {
      //     setChatIsLoading(false)
      //   })
      // }

    }, [currConversationId])

    useEffect(() => {

      if (mathFieldRef.current) {
        window.mathVirtualKeyboard.container = document.getElementById("keyboardContainer")


        // mathFieldRef.current.addEventListener("input", handleInputEvent)
        mathFieldRef.current.addEventListener('keydown', handleKeydownEvent);

        mathFieldRef.current.addEventListener("focus", () => {
          window.mathVirtualKeyboard.layouts = [
            {
              label: "Basic",
              tooltip: "Basic",
              rows: [
                [
                  "+", "-", "\\times", "\\div", "\\frac{#@}{#?}", "=",
                  "(", ")", "\\sqrt{#0}", "#@^{#?}",
                ],
                [">", "<", "\\geq", "\\leq", "\\pm", "\\mid{#0}\\mid", "\\infty", "\\int_{#0}^{#0} ", "\\lim_{#0\\to #0}"],
              ]
            }, {
              label: "Trigonemetry",
              tooltip: "Trigonemetry",
              rows: [
                ["\\pi", "\\sin({#0})", "\\cos({#0})", "\\tan({#0})", "\\csc({#0})", "\\sec({#0})", "\\cot({#0})",],
                ["\\log_{#0} {#0}", "\\log({#0})", "\\ln({#0})"],

              ]
            }]
          window.mathVirtualKeyboard.visible = true




        })



        mathFieldRef.current.addEventListener("geometrychange", () => {
        




        });


        mathFieldRef.current.addEventListener("focusin", (ev: FocusEvent) => {


       
          window.mathVirtualKeyboard.show()
          handleToggleKeyboardCollapsed(false)

        });
        mathFieldRef.current.addEventListener("focusout", () => {

          window.mathVirtualKeyboard.hide()
          handleToggleKeyboardCollapsed(true)

        });

        return () => {
          if (mathFieldRef.current) {
            // mathFieldRef.current.removeEventListener("input", handleInputEvent)
            mathFieldRef.current.removeEventListener("keydown", handleKeydownEvent)
            mathFieldRef.current.removeEventListener("focusin", () => window.mathVirtualKeyboard.show());
            mathFieldRef.current.removeEventListener("focusout", () => window.mathVirtualKeyboard.hide());
          }


        }
      }
    }, [container, value, showMathKeyboard])

    useEffect(() => {
      if (showMathKeyboard && mathFieldRef.current) {
        mathFieldRef.current.focus()
        


      } else {

      }

    }, [showMathKeyboard])



    useEffect(() => {
      // if (inputRef.current) {
      //   inputRef.current.focus()
      // }
    })

    useEffect(() => {
      if (chatInputRef.current) {
        setInputAreaHeight(chatInputRef.current.clientHeight)
      }
    }, [inputAreaHeight, inConversation])

    useEffect(() => {
      if (!signedIn) {
        resetState()
      }
    }, [inConversation]);


    const scrollToBottom = () => {
      const container = containerRef.current
      if (container) {
        container.scrollTop = container.scrollHeight
      }
    }

    const resetState = () => {
      setValue("")
      setMessages([])
      //setApiMessages([])
      latex = ""
      setInConversation(false)
      setCurrThreadID("none")
      setCurrConversationId("none")


    }

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];

      if (file) {
        if (isValidFileType(file.type)) {
          // For image files, create a URL to use as a preview
          setSelectedFile(file)

          if (file.type.startsWith('image/')) {
            const url = URL.createObjectURL(file);
            setFilePreview({ name: file.name, url, type: file.type });
          } else {
            // For non-image files, you might want to just store the file name
            setFilePreview({ name: file.name, url: '', type: file.type });
          }

        } else {
          showSnackbar("Invalid file type! (we accpet, pdf, docx, and txt files)", "error")
          setSelectedFile(null)
        }

      }

      event.target.value = '';
    };


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value)
    }

    const handleEquationInputChange = (target: HTMLInputElement) => {
      setEquationValue(target.value)
    }

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value)
    }

    const handleToggleKeyboardCollapsed = (collapsed: boolean) => {

      if (container) {
        if (!collapsed) {
          setKeyboardHeight((container.offsetHeight - window.mathVirtualKeyboard.boundingRect.height - 5))
          container.style.height = (container.offsetHeight - window.mathVirtualKeyboard.boundingRect.height) + "px";
          window.mathVirtualKeyboard.boundingRect.width = container.offsetWidth

        } else {
          setKeyboardHeight(0)
          container.style.height = containerHeight + "px";
        }

      }
      setKeyboardCollapsed(collapsed)
    }



    useEffect(() => {
      if (messages.length > 0) {
        scrollToBottom()
      }
    }, [messages, loadingResponse]);


    const parseQuestionForSending = (input: string): [question: string, latex: string] => {

      const parts = input.split(/(\\text\{.*?\})/).filter(part => part !== '');;
      let processedString = '';
      let latexParts: string[] = [];


      for (let i = 0; i < parts.length; i++) {
        if (!parts[i].startsWith('\\text')) {
          latexParts.push(parts[i]);
        }
        processedString += parts[i];
      }

      if (latexParts.length < 1) {
        latexParts[0] = ""
      }
      return [processedString, latexParts[0]]

    }

    const pollRunStatus = async (threadID: string, runID: string, getMessages: () => void) => {
      let runStatus = await manager.retrieveRunStatus(threadID, runID);

      if (runStatus === RunStatus.Completed) {

        getMessages()
      } else if (runStatus === RunStatus.Error || runStatus === RunStatus.Failed || runStatus === RunStatus.Expired) {
        showSnackbar("Error: could not process your request right now, please try again.", "error")
        setLoadingResponse(false)
        //getMessages()
        return

      } else {
        setTimeout(() => pollRunStatus(threadID, runID, getMessages), 1000); // Adjust the delay as needed
      }
    };

    const handleSendMessage = async () => {


      if (value === "" && equationValue === "" && selectedFile === null) {
        return
      }

      // change this when going back to mathlive keyboard
      let textValue = `${value}`

      let [currQuestion, currLatex] = parseQuestionForSending(textValue)
      currLatex = equationValue

      await setValue("")
      await setEquationValue("")
      await setShowMathKeyboard(false)

      removePreview()

      if (mathFieldRef.current) {
        mathFieldRef.current.value = ""
      }
      currLatex = currLatex.replace(" ", "");
      if (currLatex !== "") {
        currLatex = `@@@${currLatex}@@@\n\n`
        currQuestion = currLatex + currQuestion
      }
      if (messages.length === 0) {

        await initializeConversation(currLatex, currQuestion, selectedFile)

        return

      }






      if (currThreadID !== "none") {
        const newMessage: ThreadMessage = {
          role: "user",
          text: currQuestion,
          threadID: currThreadID,
          file_ids: [],
          image_file: "none",
          created_at: "none",
          metadata: selectedFile ? { attachedFileType: selectedFile.type, attachedFileName: selectedFile.name } : {}
        }
        setMessages([...messages, newMessage])


        setLoadingResponse(true)
        const addThreadMessageRes: ThreadResponse = await manager.addThreadMessage(currThreadID, currQuestion, selectedFile)

        pollRunStatus(addThreadMessageRes.threadID, addThreadMessageRes.runID, async () => {
          let threadMessages = await manager.getThreadMessages(addThreadMessageRes.threadID)
          if (threadMessages.length === 0) {
            showSnackbar("Error: could not process your request right now, please try again.", "error")
            setLoadingResponse(false)
            return
          }
      
          setMessages(threadMessages.reverse())
          setLoadingResponse(false)

        });

      } else {
        showSnackbar("Error: could not process your request right now, please try again.", "error")
      }




    }

    const initializeConversation = async (latex: string, question: string, file?: File | null) => {
      resetState()
      setInConversation(true)
      // latex = latex.replace(" ", "");
      // if (latex !== "") {
      //   latex = `@@@${latex}@@@ `
      //   question = latex + question
      // }


      let tempMessages = []
      const tempNewMessage: ThreadMessage = {
        role: "user",
        text: question,
        threadID: "none",
        file_ids: [],
        image_file: "none",
        created_at: "none",
        metadata: file ? { attachedFileType: file.type, attachedFileName: file.name } : {}
      };

      tempMessages = [tempNewMessage]
      setMessages(tempMessages)


      setLoadingResponse(true)
      let conversationName = await manager.queryOpenAI(`Create a short conversation title based on this first message of a conversation, be concise and specific, use 3-4 words, ignore any latex text formatting: ${question}`)



      // let response = await manager.createConversation(conversationName.replace(/"/g, ""), question, latex)
      let createThreadRes: ThreadResponse = await manager.createThread(question, conversationName.replace(/"/g, ""), file)
      if (createThreadRes.runStatus === RunStatus.Error || createThreadRes.runStatus === RunStatus.Failed) {
        showSnackbar("Error: could not process your request right now, please try again.", "error")
        setLoadingResponse(false)
        return
      }

      setCurrThreadID(createThreadRes.threadID)

      pollRunStatus(createThreadRes.threadID, createThreadRes.runID, async () => {
        let threadMessages = await manager.getThreadMessages(createThreadRes.threadID)
        if (threadMessages.length === 0) {
          showSnackbar("Error: could not process your request right now, please try again.", "error")
          setLoadingResponse(false)
          return
        }

        setMessages(threadMessages.reverse())
        setThreads(prevThreads => [{ id: createThreadRes.threadID, created_at: "none", thread_id: createThreadRes.threadID, user_id: "none", name: conversationName.replace(/"/g, "") }, ...prevThreads]);
        setLoadingResponse(false)

      });



    }

    const removePreview = () => {
      // Clean up the object URL to avoid memory leaks
      setFilePreview(null)
    };

    const actions = [
      { icon: <DigitAddAttachmentIcon />, name: 'Add Attachment' },
      { icon: <DigitAddEquationIcon />, name: 'Add Equation' },

    ]

    if (isVisible) {
      return (
        <ThemeProvider theme={theme}>

          <div id="keyboardContainer" style={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            position: "fixed",
            width: "inherit",
            height: "inherit",


          }}>
            <div id="container" style={{
              display: "flex",
              flexDirection: "column",
              position: "fixed",
              width: "inherit",
              height: "inherit",
              borderBottomLeftRadius: "inherit",




            }}>
              <div ref={containerRef} style={{ height: `calc(100% - ${inputAreaHeight}px - 2rem`, flexGrow: "1", overflowY: 'auto', padding: "1rem" }}>
                {chatIsLoading ? <Box marginTop={"3rem"} flexGrow={1} display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Box> :
                  <>{
                    messages.map((message, index) => (
                      <div key={index}>
                        <div>
                          <ChatBubble message={message} />
                        </div>
                      </div>
                    ))
                  }
                    {loadingResponse && <ChatBubble message={{ text: "test", role: "typing", threadID: "none", file_ids: [], image_file: "none", created_at: "none", metadata: {} }} />}</>}
              </div>



              <Box display={"flex"} flexDirection={"row"} width={"100%"} justifyContent={"space-between"} >

                {/* attchment buttons */}
                <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignContent={"center"}>

                  <input type="file" style={{ display: "none" }} ref={fileInputRef} onChange={handleFileSelect}></input>
                  <SpeedDial

                    ariaLabel="Chat Options"
                    sx={{ position: "static", zIndex: "4", alignSelf: "center", marginBottom: "1.5rem", marginLeft: "1rem", }}
                    icon={<SpeedDialIcon sx={{
                      width: "4rem",
                      height: "auto",
                    }} />}


                  >

                    <SpeedDialAction
                      icon={<DigitAddAttachmentIcon />}
                      tooltipTitle={"Add Attachment"}
                      onClick={() => {
                        fileInputRef.current?.click()
                      }} />
                    {!isMobile && <SpeedDialAction
                      icon={<DigitAddEquationIcon />}
                      tooltipTitle={"Add Equation"}
                      onClick={() => {
                        showMathKeyboard ? setShowMathKeyboard(false) : setShowMathKeyboard(true)
                      }} />}
                  </SpeedDial>



                </Box>


                <Box flexGrow={1} margin={"1rem"} justifyContent="flex-end" display="flex" alignItems="inherit" flexDirection="column" sx={{ position: "relative" }} width={"100%"} mr="2.5rem">
                  {/* MATH KEYBOARD */}
                  {showMathKeyboard &&
                    <Box border={"solid 1px #7B71BB"} margin={"1rem"} justifyContent="flex-start" display="flex" flexDirection="column" sx={{ position: "relative" }} alignSelf={"flex-start"} flexGrow={"1"} minWidth={"50%"}>

                      <button
                        style={{
                          zIndex: 1,
                          width: "inherit",
                          position: "absolute",
                          top: "-0.5rem",
                          right: "-0.5rem",
                          cursor: "pointer",
                          backgroundColor: "transparent",
                          border: "none",
                          outline: "none",
                        }}
                        onClick={() => {
                          setShowMathKeyboard(false)
                        }}
                      >
                        <DigitRemoveIcon style={{
                          width: "1.25rem",
                          height: "auto",

                        }} ></DigitRemoveIcon>
                      </button>

                      <Box sx={{
                        position: 'absolute',

                        width: '100%',
                        height: '100%',
                        backgroundColor: '#F3F3F3',
                        zIndex: 0,
                        pointerEvents: 'none',

                        borderWidth: "0.5px",
                        borderStyle: "solid",
                        borderColor: "#000",
                        boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.2)",

                      }}>

                      </Box>

                      <Box display="flex" alignItems="flex-start" margin={"0.5rem"} justifyContent="center" flexDirection={"column"}
                        sx={{
                          // marginBottom: '1rem',
                        }}>


                        <Box display={"flex"} width={"inherit"} >

                          <math-field
                            math-virtual-keyboard-policy="manual"

                            id="mathfield"
                            ref={mathFieldRef}
                            placeholder='Type \ your \ equation \ here...'
                            onInput={
                              (event: React.FormEvent<MathfieldElement>) => {
                                event.preventDefault()
                                const target = event.target as HTMLInputElement
                                handleEquationInputChange(target)
                              }

                            }
                            style={{ overflow: "auto", fontSize: "1.22rem" }}
                          >
                            {equationValue}
                          </math-field>


                        </Box>




                      </Box>
                    </Box>}






                  {/* CHAT INPUT */}
                  <Box ref={chatInputRef} justifyContent="flex-start" display="flex" alignItems="inherit" flexDirection="column" sx={{ position: "relative" }} >
                    <Box sx={{
                      position: 'absolute',

                      width: '100%',
                      height: '100%',
                      backgroundColor: '#F3F3F3',
                      zIndex: 0,
                      pointerEvents: 'none',
                      borderRadius: "50px",
                      borderWidth: "0.5px",
                      borderStyle: "solid",
                      borderColor: "#000",
                      boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.2)",

                    }}></Box>

                    <Box display="flex" alignItems="flex-start" margin={"1rem"} justifyContent="center" flexDirection={"column"}
                      sx={{
                        // marginBottom: '1rem',
                      }}>
                      <div>{filePreview && <FilePreviewComponent removePreview={removePreview} filePreview={filePreview} />}</div>

                      <Box display={"flex"} width={"100%"} >
                        <TextField
                          sx={{
                            backgroundColor: '#F3F3F3',
                            borderRadius: "25px",
                            outline: "none",
                            marginX: "1rem",
                            display: "flex",
                            flexGrow: "1",
                            alignSelf: "center"

                          }}

                          inputRef={inputRef}
                          autoFocus
                          multiline
                          fullWidth={true}
                          variant="standard"
                          placeholder="Type your question..."
                          disabled={loadingResponse}
                          value={value}
                          onChange={handleInputChange}
                          maxRows={4}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault()
                              handleSendMessage();
                            }
                          }}
                          InputProps={{ disableUnderline: true }}
                        />
                        <div style={{
                          zIndex: "1",
                          cursor: "pointer",
                          maxHeight: "2.5rem",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center"
                        }}>
                          <DigitSendIcon style={{ height: "auto", }} onClick={() => {
                            if (!loadingResponse || value !== "") {
                              handleSendMessage()
                            }
                          }} />

                        </div>
                      </Box>
                    </Box>
                  </Box>

                </Box>



              </Box>


              {!isMobile && <div style={{
                display: "flex",
                fontFamily: "Source Sans Pro",
                fontWeight: "bold",
                width: "100%",
                minHeight: "5%",
                backgroundColor: "#E0DCFF",
                borderTop: "1px solid #000",
                borderBottom: "1px solid #000",
                textAlign: "center",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
                onClick={() => {

                  if (showMathKeyboard && mathFieldRef.current) {
                    mathFieldRef.current.focus()


                  } else {
                    setShowMathKeyboard(true)
                  }


                }}><p>f(x)</p> {keyboardCollapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </div>}






            </div>

          </div></ThemeProvider>);
    } else {
      return (<div style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        width: "inherit",
        height: "inherit",
        borderBottomLeftRadius: "inherit"
      }}></div>)
    }
  });

export default ChatDialogFunctional;