import { CSSProperties, FunctionComponent, useEffect, useState } from "react";
import { supabase } from "../lib/supabaseClient";
import { UserResponse } from "@supabase/gotrue-js";
import { CircularProgress, ThemeProvider, createTheme } from "@mui/material";
import TextField from '@mui/material/TextField';
import "@fontsource/poppins/500.css";
import { Button } from "../components/CustomMUIComponents";

interface AccountPageProps {

}

interface Profile {
  id: string
  updated_at: string
  username: string
  first_name: string
  last_name: string
  avatar_url: string | null
  email: string
  birthday: string
  age: number
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#462ED8"

    },
    secondary: {
      main: "#FFFFFF"
    },
    warning: {
      main: "#FB754D"
    },
    error: {
      main: "#FB754D"
    }
  },
});

const AccountPage: FunctionComponent<AccountPageProps> = () => {
  const [profile, setProfile] = useState<Profile | null>(null)
  const [loading, setLoading] = useState(true)
  const [feedback, setFeedback] = useState(''); // Feedback state
  const [error, setError] = useState("")
  const [feedbackReceived, setFeedbackReceived] = useState(false)

  const handleFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedbackReceived(false);
    setFeedback(event.target.value);
  };

  const handleSendFeedback = async () => {
    if (!profile) {
      alert('You must be logged in to send feedback.');
      return;
    }

    try {
      const { error } = await supabase.from('feedback').insert([
        { user_id: profile.id, text: feedback }
      ]);

      if (error) throw error;
      setFeedback(''); // Clear feedback field after sending
      setFeedbackReceived(true)
    } catch (error: any) {
      alert(error.message);
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const userResponse: UserResponse = await supabase.auth.getUser()

        if (userResponse.data.user) {
          let { data, error } = await supabase
            .from('profiles')
            .select("*")
            .eq("id", userResponse.data.user.id)
            .single()

          if (error) {
            throw error
          }

          setProfile(data)
        }
      } catch (error: any) {
        setError(error.message)
      } finally {
        setLoading(false)
      }
    }

    fetchProfile()
  }, [])

  if (loading) {
    return (<div style={{
      backgroundColor: "#EFEDFF",
      display: "flex",
      flexDirection: "column",
      position: "fixed",
      width: "inherit",
      height: "inherit",
      borderBottomLeftRadius: "inherit",
      justifyContent: "center",
      alignItems: "center"
    }}>
      <ThemeProvider theme={theme}>
        <CircularProgress color="primary" />

      </ThemeProvider>

    </div>)
  }

  return (
    <div style={{
      backgroundColor: "#EFEDFF",
      display: "flex",
      flexDirection: "column",
      position: "fixed",
      width: "inherit",
      height: "inherit",
      borderBottomLeftRadius: "inherit",
      overflowY: 'auto', // Enable vertical scrolling
    }}>
      {/* Account Information */}
      <div style={{
        marginTop: "5rem",
        marginLeft: "min(12rem, 15%)",
        width: "min(20rem, 30%)",
      }}>
        <h1 style={styles.heading}>
          Account
        </h1>

        {(!loading && profile) ?
          <div style={{
            marginTop: "1rem",

          }}>
            <div style={styles.paragraphGroup}>
              <h4 style={styles.subHeading}>
                Username/Email
              </h4>
              <p style={styles.paragraph}>{profile.email}</p>
            </div>

            <div style={styles.paragraphGroup}>
              <h4 style={styles.subHeading}>
                Display Name
              </h4>
              <p style={styles.paragraph}>{`${profile.first_name} ${profile.last_name}`}</p>
            </div>

            <Button uiMode="light" onClick={() => {
              supabase.auth.signOut()
            }} sx={{ width: "auto", mt: "2rem" }} variant="contained">Sign Out
            </Button>
          </div> :
          <div>
            {/* Placeholder for loading or error display */}
          </div>
        }
      </div>

      {/* Contact Us Section */}
      <div style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "5rem",
        marginLeft: "min(12rem, 15%)",
        width: "min(30rem, 50%)",
        marginBottom: "3rem"
      }}>
        <h1 style={styles.heading}>
          Contact Us
        </h1>
        <TextField
        sx={{mb: "0.5rem"}}
          id="filled-multiline-static"
          label="Feedback"
          multiline
          rows={5}
          defaultValue=""
          variant="filled"
          value={feedback}
          onChange={handleFeedbackChange}
        />
        {feedbackReceived &&
          <div style={styles.paragraphGroup}>
            <p style={styles.paragraph}>Thank you for your feedback! We will review it soon.</p>
          </div>
        }
        <div>
          <Button uiMode="light" onClick={handleSendFeedback} sx={{ width: "auto", mt: "1rem", flexShrink: "1" }} variant="contained">
            Send
          </Button>
        </div>
      </div>
    </div>
  );
}

const styles: { [key: string]: CSSProperties } = {
  heading: {
    fontSize: '32px',
    fontWeight: "bold",
    fontFamily: "Poppins"

  },
  subHeading: {
    fontSize: '24px',
    fontWeight: "bold",
    fontFamily: "Poppins"


  },
  paragraph: {
    fontSize: '16px',
    fontFamily: "Poppins"

  },

  paragraphGroup: {
    marginBottom: "0.75rem",
    wordWrap: "break-word",
    whiteSpace: "normal"
  }

}

export default AccountPage;