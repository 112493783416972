import { FunctionComponent, SetStateAction, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import DrawerNavigator from "./pages/DrawerNavigator";
import SignUp from "./pages/SignUp";
import Courses from "./components/Courses";
import { SnackbarProvider } from "./components/SnackbarContext";
import { supabase } from "./lib/supabaseClient";
import ChatDialogFunctional from "./components/ChatDialogFunctional";
import Account from "./pages/Account";
import LandingPage from "./pages/LandingPage/LandingPage";
import ForgotPassword from "./pages/ForgotPassword";
import RecoverPassword from "./pages/RecoverPassword";
import { Thread } from "./lib/types";


interface ApplicationProps {

}

const Application: FunctionComponent<ApplicationProps> = (props) => {
  const [signedIn, setSigngedIn] = useState(true)
    const [session, setSession] = useState<any>()
  const [user, setUser] = useState<any>(null)


  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
      if (session) {
        setSigngedIn(true)
        setSession(session)
        setUser(session.user)
      } else {
        setSigngedIn(false)
        setSession(null)
        setUser(null)
      }
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
      if (session) {
        setSigngedIn(true)
        setSession(session) 
        setUser(session.user)
      } else {
        setSigngedIn(false)
        setSession(null)
        setUser(null)
      }

    })


    return () => subscription.unsubscribe()
  }, [])



  return (
    <SnackbarProvider>
      <BrowserRouter>
      <Routes>
        <Route path='login' element={<Login></Login>} />
        <Route path="/" element={<LandingPage></LandingPage>} />
        <Route path="digit"  element={<DrawerNavigator user={user} signedIn={signedIn}></DrawerNavigator>} >
          <Route index element={<ChatDialogFunctional signedIn={signedIn} setThreads={function (value: SetStateAction<Thread[]>): void {
              throw new Error("Function not implemented.");
            } }></ChatDialogFunctional>} />
          <Route path="math-chat" element={<ChatDialogFunctional signedIn={signedIn} setThreads={function (value: SetStateAction<Thread[]>): void {
              throw new Error("Function not implemented.");
            } }></ChatDialogFunctional>} />
          <Route path="courses" element={<Courses></Courses>}/>
          <Route path="*" element={<ChatDialogFunctional signedIn={signedIn} setThreads={function (value: SetStateAction<Thread[]>): void {
              throw new Error("Function not implemented.");
            } }></ChatDialogFunctional>}/>
          <Route path="account" element={<Account></Account>}/>
        </Route>
        <Route path="sign-up" element={<SignUp></SignUp>} />
        <Route path="forgot-password" element={<ForgotPassword></ForgotPassword>}/>
        <Route path="recover-password" element={<RecoverPassword></RecoverPassword>}/>
      </Routes>
    </BrowserRouter>

    </SnackbarProvider>
    );
}

export default Application;