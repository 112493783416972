import { FunctionComponent } from "react";
import SignInComponent from "../components/SignInComponent";
import { Box, AppBar } from "@mui/material";
import { ReactComponent as DigitIcon } from "../assets/DigitLogo.svg"
import { useNavigate } from 'react-router-dom'

interface LoginProps {

}


const Login: FunctionComponent<LoginProps> = () => {
  const navigate = useNavigate()

  return (
    <Box style={{ backgroundColor: "white" }} width="100%" height={"100vh"} display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" >
      <AppBar elevation={0} sx={{ backgroundColor: "white" }}>
        <DigitIcon onClick={() => navigate("../")} style={{
          flexGrow: 1,
          color: '#00000',
          marginLeft: '2rem',
          marginTop: "1rem",
          marginBottom: "1rem",
          fontFamily: 'Poppins',
        }} />
      </AppBar>


      <SignInComponent></SignInComponent>


    </Box>
  );
}

export default Login;