import React from 'react';

interface DigitImageProps {
  base64Image: string;
}

const DigitImageComponent: React.FC<DigitImageProps> = ({ base64Image }) => {
  return (
    <div style={{
      margin: "0.5rem",
    }}>
      <img src={`data:image/jpeg;base64,${base64Image}`} alt='From Digit' />
    </div>
  );
};

export default DigitImageComponent;