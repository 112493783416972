
import { Thread, CreateConversationResponse, DeleteConversationResponse, DigitMessage, GetThreadsResponse, Message, MessageResponse, RunStatus, ThreadMessage, ThreadResponse, Conversation, } from './types';
import { supabase } from './supabaseClient';
import { AuthError } from '@supabase/supabase-js';

export class Manager {
  BASE_URL: string;

  constructor(baseURL: string | undefined) {
    this.BASE_URL = baseURL ?? "bad url"; // Base URL of your API


  }

  sendMessage = async (messages: Message[], latexString: string, conversationID: string, file?: File): Promise<MessageResponse | undefined> => {
    try {
      const formData = new FormData()
      formData.append('messages', JSON.stringify(messages));
      formData.append('latexString', latexString);
      formData.append('conversationID', conversationID);

      if (file) {
        formData.append('file', file);
      }


      const response = await fetch(`${this.BASE_URL}/api/aiva/aiva-continue-chat`, {
        method: 'POST',
        body: formData

      });

      if (!response.ok) {

        throw new Error('Network response was not ok ' + response.statusText);
      }
      const data = await response.json();

      return data as MessageResponse
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
    }
  };


  createConversation = async (conversationName: string, newMessage: string, latexString?: string): Promise<CreateConversationResponse | undefined> => {
    try {
      const token = (await supabase.auth.getSession()).data.session?.access_token

      const response = await fetch(`${this.BASE_URL}/api/digit/createConversation`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          name: conversationName,
          newMessage: newMessage,
          latexString: latexString || ""
        })
      })

      if (!response.ok) {
        throw new Error(`Network response was not ok ` + response.statusText)
      }

      const data = await response.json()

      return data as CreateConversationResponse
    } catch (error) {
      console.error("There has been a problem with your fetch operation", error)
    }
  }

  queryOpenAI = async (prompt: string): Promise<string> => {
    try {
      const token = (await supabase.auth.getSession()).data.session?.access_token

      const response = await fetch(`${this.BASE_URL}/api/digit/openai`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          prompt: prompt
        })
      })

      if (!response.ok) {
        throw new Error("Network response was not ok " + response.statusText)
      }

      const data = await response.json()

      return data.response

    } catch (error) {
      console.error("There has been a problem with your fetch operation " + error)
      throw new Error("There has been a problem with your fetch operation " + error)
    }

  }

  // getUserConversations = async (userId: string): Promise<GetConversationsResponse> => {
  //   let { data, error } = await supabase
  //     .from("conversations")
  //     .select("*")
  //     .eq("user_id", userId)
  //     .order("created_at", { ascending: false })

  //   if (error) {
  //     console.error('Error fetching data:', error)
  //     throw new Error(error.message)
  //   }

  //   try {
  //     let conversations = data as Conversation[]
  //     return {
  //       userId: userId,
  //       conversations
  //     }
  //   } catch (error) {
  //     console.error("Error in parsing conversations", error)
  //     throw new Error("Error parsing conversations" + error)
  //   }



  // }

  deleteConversation = async (conversationId: string): Promise<DeleteConversationResponse> => {
    try {
      const token = (await supabase.auth.getSession()).data.session?.access_token

      const response = await fetch(`${this.BASE_URL}/api/digit/deleteConversation/${conversationId}`, {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })

      if (!response.ok) {
        throw new Error(`Network response was not ok ` + response.statusText)
      }

      const data = await response.json()

      return data as DeleteConversationResponse
    } catch (error) {
      console.error("There has been a problem with your fetch operation", error)
      throw new Error("There has been a problem with your fetch operation" + error)
    }

  }

  getConversationMessages = async (conversationId: string): Promise<DigitMessage[]> => {
    try {
      const token = (await supabase.auth.getSession()).data.session?.access_token

      const response = await fetch(`${this.BASE_URL}/api/digit/getConversationMessages?conversationID=${conversationId}`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })

      if (!response.ok) {
        throw new Error(`Network response was not ok ` + response.statusText)
      }
      const data = await response.json()
      return data as DigitMessage[]
    } catch (error) {
      throw new Error("There has been a problem with your fetch operation" + error)
    }
  }

  getConversation = async (conversatinID: string): Promise<Conversation> => {
    let { data, error } = await supabase
      .from("conversations")
      .select("*")
      .eq("id", conversatinID)
      .single()

    if (error) {
      console.error('Error fetching data:', error)
      throw new Error(error.message)
    }

    try {
      return data
    } catch (error) {
      console.error("Error in parsing conversations", error)
      throw new Error("Error parsing conversations" + error)
    }



  }


  sendNewMessage = async (conversationID: string, newMessage: string, latexString: string): Promise<DigitMessage[]> => {
    try {
      const token = (await supabase.auth.getSession()).data.session?.access_token

      const response = await fetch(`${this.BASE_URL}/api/digit/sendMessage`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          conversationID: conversationID,
          newMessage: newMessage,
          latexString: latexString,

        })
      })

      if (!response.ok) {
        throw new Error("Network response was not ok " + response.statusText)
      }

      const data = await response.json()

      return data.messages

    } catch (error) {
      console.error("There has been a problem with your fetch operation " + error)
      throw new Error("There has been a problem with your fetch operation " + error)
    }

  }

  createThread = async (initialMessage: string, conversationName: string, file?: File | null): Promise<ThreadResponse> => {
    try {
      const token = (await supabase.auth.getSession()).data.session?.access_token

      const formData = new FormData();
      formData.append('initialMessage', initialMessage);
      formData.append('conversationName', conversationName);
      if (file) {
        formData.append('file', file);
      }

      const response = await fetch(`${this.BASE_URL}/api/digit/createThread`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
      const data = await response.json();

      return data as ThreadResponse
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);

      return {
        threadID: "error",
        runID: "error",
        runStatus: RunStatus.Error
      }
    }

  }

  retrieveRunStatus = async (threadID: string, runID: string): Promise<RunStatus> => {
    try {
      const token = (await supabase.auth.getSession()).data.session?.access_token

      const response = await fetch(`${this.BASE_URL}/api/digit/retrieveRun/${threadID}/${runID}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
      const data = await response.json();

      return data.runStatus as RunStatus
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return RunStatus.Error
    }

  }

  getThreadMessages = async (threadID: string): Promise<ThreadMessage[]> => {
    try {
      const token = (await supabase.auth.getSession()).data.session?.access_token

      const response = await fetch(`${this.BASE_URL}/api/digit/listMessages/${threadID}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
      const data = await response.json();


      return data as ThreadMessage[]
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return []
    }
  }

  addThreadMessage = async (threadID: string, content: string, file?: File | null): Promise<ThreadResponse> => {
    try {
      const token = (await supabase.auth.getSession()).data.session?.access_token

      const formData = new FormData();
      formData.append('content', content);
      formData.append('threadID', threadID);
      if (file) {
        formData.append('file', file);
      }

      const response = await fetch(`${this.BASE_URL}/api/digit/addThreadMessage`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }

      const data = await response.json();

      return data as ThreadResponse

      } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
        return {
          threadID: "error",
          runID: "error",
          runStatus: RunStatus.Error
        }
      }

  }

  getThreads = async (user_id: string): Promise<GetThreadsResponse> => {
    try {
      const token = (await supabase.auth.getSession()).data.session?.access_token

      const response = await fetch(`${this.BASE_URL}/api/digit/getThreads/${user_id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
      const data = await response.json();
      data.sort((a: { created_at: string | number | Date; }, b: { created_at: string | number | Date; }) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateA.getTime() - dateB.getTime();
      });


      return {
        userId: user_id,
        threads: data as Thread[]
      }
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return {
        userId: "error",
        threads: []
      }
    }

  }

  deleteThread = async (threadID: string): Promise<DeleteConversationResponse> => {
    try {
      const token = (await supabase.auth.getSession()).data.session?.access_token

      const response = await fetch(`${this.BASE_URL}/api/digit/deleteThread/${threadID}`, {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })

      if (!response.ok) {
        throw new Error(`Network response was not ok ` + response.statusText)
      }

      const data = await response.json()

      return data as DeleteConversationResponse

    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return {
        success: false,
        message: "error"
      }
    }

  }

}



