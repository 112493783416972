import { FunctionComponent, useEffect, useRef, useState } from "react";
import { supabase } from "../lib/supabaseClient";
import { Outlet, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import "@fontsource/poppins/500.css";
import * as React from 'react';
import { styled, useTheme, Theme, CSSObject, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import DescriptionIcon from '@mui/icons-material/Description';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as DigitIcon } from "../assets/digit_icon.svg"
import { Button } from "../components/CustomMUIComponents";
import AddNewCourseDialog from "../components/NewCourseDialog";
import { useSnackbar } from "../components/SnackbarContext";
import { Session, User } from "@supabase/supabase-js";
import FormDialog from "../components/NewSessionDialog";
import Courses from "../components/Courses";
import ChatDialogFunctional, { ChatDialogFunctionalRef } from "../components/ChatDialogFunctional";
import { AnyARecord } from "dns";
import { QueryBuilder } from "@mui/icons-material";
import { Context } from "vm";
import { useMediaQuery } from 'react-responsive';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountPage from "./Account";
import ChatHistoryList from "../components/ChatHistoryList";
import { Thread } from "../lib/types";
import { Manager } from "../lib/manager";




const drawerWidth = "300px";

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: 'transform 0.7s ease-in-out',
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: 'transform 0.7s ease-in-out',
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },

});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),

  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "white",

  ...(open ? {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth})`,
    transition: 'transform 0.7s ease-in-out'
  } : {
    width: `calc(100% - (${theme.spacing(7)} + 1px))`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - (${theme.spacing(8)} + 1px))`,
    },
    transition: 'transform 0.7s ease-in-out'
  }),
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    height: "100vh",
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),

    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const InnerDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'chatDrawerOpen' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),

    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


interface DrawerNavigatorProps {
  signedIn: boolean
  user: User | null

}

const DrawerNavigator: FunctionComponent<DrawerNavigatorProps> = ({ signedIn, user }) => {
  const [session, setSession] = useState<any>()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const [chatDrawerOpen, setChatDrawerOpen] = useState(false)
  const [coursesDrawerOpen, setCoursesDrawerOpen] = useState(false)
  const [appBarTitle, setAppBarTitle] = useState("Chat With Digit")
  const { showSnackbar } = useSnackbar();
  const appBarRef = useRef<HTMLDivElement | null>(null)
  const [appBarHeight, setAppBarHeight] = useState(0)
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight)
  let route = useLocation();
  const [latex, setLatex] = useState("")
  const [question, setQuestion] = useState("")
  const [conversationsLoading, setConversationsLoading] = useState(true)
  const [threads, setThreads] = useState<Thread[]>([])
  let numChats = 0
  const isSmallScreen = useMediaQuery({ maxWidth: 600 });
  const manager = new Manager(process.env.REACT_APP_API_BASE_URL)
  const chatDialogRef = useRef<ChatDialogFunctionalRef>(null);

  const [selectedThreadId, setSelectedThreadId] = useState("none")


  const resetChatState = () => {
    if (chatDialogRef.current) {
      chatDialogRef.current.resetChatState()
    }
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: "#1232A0"

      },
      secondary: {
        main: "#FFFFFF"
      },
      warning: {
        main: "#FB754D"
      },
      error: {
        main: "#FB754D"
      }
    },
  });



  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);

  }, [window.innerHeight])

  useEffect(() => {
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.clientHeight)


    }

  }, [appBarHeight])

  useEffect(() => {
    if (route.pathname.includes("math-chat")) {
      setAppBarTitle("Chat With Digit")
    }

    if (route.pathname.includes("courses")) {
      setAppBarTitle("Upload Homework")
    }


  }, [route.pathname])

  useEffect(() => {

    if (!signedIn) {
      navigate("../login")
    }

  }, [signedIn])




  const handleDrawerOpen = () => {
    if (route.pathname.includes("math-chat")) {
      setChatDrawerOpen(true)
    }

    if (route.pathname.includes("courses")) {
      setCoursesDrawerOpen(true)
    }
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setChatDrawerOpen(false)

  };

  const handleChatDrawerClose = () => {
    setChatDrawerOpen(false)
  }

  const handleCoursesDrawerClose = () => {
    setCoursesDrawerOpen(false)
  }

  const handleShowSnack = (success: boolean, message: string) => {

    if (success) {
      showSnackbar(message, "success")

    } else {
      showSnackbar(message, "error")

    }


  }

  const fetchThreads = async (): Promise<Thread[] | null> => {
    if (user) {
      setConversationsLoading(true)
      try {
        const conversations = await manager.getThreads(user.id);

        return conversations.threads.reverse()
      } catch (error) {
        console.error("Error fetching conversations: ", error);
        return null
      } finally {
        setConversationsLoading(false)
      }
    } else {
      return null
    }
  };

  useEffect(() => {
    fetchThreads().then((threads) => {
      if (threads) {
        setThreads(threads)
      }

    })
  }, [user])

  const selectThread = (threadId: string) => {
    setSelectedThreadId(threadId)
    if (chatDialogRef.current) {
      chatDialogRef.current.loadConversation(threadId)
    }

  }


  return (<Box height="100%" sx={{ display: 'flex', backgroundColor: "#462ED8" }} >
    <ThemeProvider theme={theme}>

      <CssBaseline />

      <AppBar ref={appBarRef} position="fixed" open={open} sx={{
        backgroundColor: "white", borderTopLeftRadius: "2.5rem", boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",

      }} elevation={0}>
        <Box minHeight={"4rem"} maxHeight={"4.5rem"} display="flex" justifyContent="space-between" width="100%" alignItems={"center"}>
          <Box >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={() => {
                  if (open) {
                    handleDrawerClose()
                  } else {
                    handleDrawerOpen()

                  }

                }
                }
                edge="start"
                sx={{

                  fontFamily: "Poppins"

                }}
              >
                <ChevronLeftIcon fontSize="large" />
                <p>Chat</p>
              </IconButton>

              {/* <Typography justifySelf={"center"} textAlign={"center"} variant="h6" noWrap component="div" fontFamily='Poppins' color="primary">
                {appBarTitle}
              </Typography> */}


            </Toolbar>

          </Box>
          <Box flexGrow={1} width={"auto"} display={"flex"} justifyContent={"flex-end"} mr="2rem">
            {(route.pathname.includes("math-chat") || route.pathname === "/digit") && <Button style={{}} variant="outlined" onClick={() => {
              resetChatState()
            }}
            >New Chat</Button>}
          </Box>
        </Box>
      </AppBar>


      <Drawer elevation={0} variant="permanent" open={open} sx={{

        '& .MuiDrawer-paper': {
          backgroundColor: "#462ED8",
          borderColor: "#462ED8"
        },


      }}>
        <DrawerHeader  >
          <DigitIcon style={{
            flexShrink: "0",
            marginLeft: "1rem",
            height: "3rem",
            fontFamily: 'Poppins',
          }} />

        </DrawerHeader>
        <Divider />

        <Box display="flex" flexDirection="column" justifyContent="space-between" width="100%" height="100%" >


          <List style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <div>



              <ListItem key={"Chat With Digit"} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                  onClick={() => {
                    navigate("math-chat")
                    setAppBarTitle("Chat With Digit")
                    setOpen(true)
                    setChatDrawerOpen(true)


                  }}
                >
                  <ListItemIcon color="secondary"
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <ChatBubbleIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary="Chat With Digit" primaryTypographyProps={{ fontFamily: "Poppins" }}
                    sx={{ color: "white", opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </div>

            <div style={{ marginTop: "auto" }}>
              <ListItem key={"Account"} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 50,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    width: "auto"
                  }}
                  onClick={() => {
                    navigate("account")
                    if (isSmallScreen) {
                      setOpen(false)
                    }
                  }}>
                  <ListItemIcon color="secondary"
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center'
                    }}>
                    <AccountCircleIcon fontSize="large" color="secondary"></AccountCircleIcon>

                  </ListItemIcon>

                </ListItemButton>

              </ListItem>


            </div>



          </List>


        </Box>



      </Drawer>


      {/* Inner Drawer for chat history */}
      <InnerDrawer elevation={0}

        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
          disableEnforceFocus: true, // The rest of the screen will be interactive.
          disableAutoFocus: true, // Autofocus on the drawer contents is disabled.
        }}
        hideBackdrop={true} // Hides the backdrop

        open={chatDrawerOpen}
        sx={{

          '& .MuiDrawer-paper': {
            zIndex: "4",
            backgroundColor: "#462ED8",
          },

        }}>
        <DrawerHeader>
          <IconButton onClick={handleChatDrawerClose} color="secondary">
            <ArrowBackIcon></ArrowBackIcon>
          </IconButton>
        </DrawerHeader>
        <Divider />



        <ChatHistoryList handleDrawerClose={handleDrawerClose} currThreadId={selectedThreadId} resetChat={resetChatState} selectThread={selectThread} threads={threads} threadsLoading={conversationsLoading} fetchThreads={fetchThreads} user={user} setThreads={setThreads} />
      </InnerDrawer>


      <Box component="main" sx={{
        transition: 'transform 0.7s ease-in-out',
        width: open ? `calc(100% - ${drawerWidth})` : `calc(100% - (${theme.spacing(7)} + 10px))`,
        height: `calc(${viewportHeight}px - ${appBarHeight}px)`,
        mt: `${appBarHeight}px`,
        position: "relative",

      }}>

        {route.pathname.includes("courses") ? <Courses /> :
          route.pathname.includes("account") ? <AccountPage></AccountPage> :
            <ChatDialogFunctional isVisible={!(isSmallScreen && open)} setThreads={setThreads} ref={chatDialogRef} signedIn={signedIn} latex={latex} question={question} numChats={numChats} />}




      </Box>
    </ThemeProvider>
  </Box>);

}

type ContextType = {
  latex: string,
  question: string,
  numChats: number
}

export function useChatContext() {
  return useOutletContext<ContextType>()
}

interface CoursListProps {
  handleShowSnack: (success: boolean, message: string) => void


}

const CoursesList = (props: CoursListProps) => {
  return (
    <Box gap="1rem" flexDirection="column" display="flex" mt="1rem" justifyContent="center">
      <Typography variant="h5" align="center" fontFamily='Poppins' color="secondary">
        My Courses
      </Typography>
      <AddNewCourseDialog handleShowSnack={props.handleShowSnack} label="Add Course"></AddNewCourseDialog>
    </Box>
  )
}


export default DrawerNavigator;