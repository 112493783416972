import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, makeStyles, TextField, Snackbar, Alert } from "@mui/material";
import React, { useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import { Button } from "./CustomMUIComponents";
import { supabase } from "../lib/supabaseClient";


interface DialogProps {
  label: string
  handleShowSnack: (success: boolean, message: string) => void
}

export function AddNewCourseDialog(props: DialogProps) {
  const [open, setOpen] = useState(false);
  const [courseTitle, setCourseTitle] = useState("")
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([])
  const [uploading, setUploading] = useState(false)



  const handleClickOpen = () => {
    setOpen(true);
  };


  const handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    // if (reason !== 'backdropClick') {
    //   resetState()
    // }
    resetState()

  };



  const resetState = () => {
    setOpen(false)
  }

  const handleCancel = () => {
    resetState()
  }

  async function doesFolderExist(bucketName: string, folderPath: string) {
    const { data: objects, error } = await supabase.storage
      .from(bucketName)
      .list();
  
    if (error) {
      console.error('Error listing objects:', error);
      return false;
    }
  
    // Check if the folder exists in the list of objects
    const folderExists = objects.some(object => object.name.startsWith(folderPath));
  
    return folderExists;
  }


  const handleFileUpload = async (files: File[]): Promise<boolean> => {



    setUploading(true)
    const bucketName = "course_documents"
    const userId = await supabase.auth.getSession().then((value) => {
      return value.data.session?.user.id
    })

    if (userId !== null && courseTitle !== "") {

      let folderExists = await doesFolderExist(bucketName, `${userId}/${courseTitle}`)

      if (!folderExists) {
        setUploading(false)
        props.handleShowSnack(false, "Course name already exists")
        return false
      }

   
      for (const file of files) {

        const filePath = `${userId}/${courseTitle}/${file.name}`

        const { data, error } = await supabase.storage.from(bucketName).upload(filePath, file);

        if (error) {
          console.error('Error uploading file:', error);
          props.handleShowSnack(false, "error uploading file")
          setUploading(false)
          return false
        } else {

          props.handleShowSnack(true, "File successfully uploaded")
          setUploading(false)
          return true
        }

      }

    } else {
      setUploading(false)
      props.handleShowSnack(false, "error getting user")
      return false
      // display error
    }
    setUploading(false)
    return false




  }

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCourseTitle(event.target.value)

  };

  const handleFilesDrop = (files: File[]) => {

    setUploadedFiles(files)

  }




  return (
    <div style={{

      alignSelf: "center"
    }}>
      <Button uiMode="light" sx={{ width: "auto" }} variant="contained" startIcon={<AddIcon />} onClick={handleClickOpen}>
        {props.label}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {"Upload Homework"}
        </DialogTitle>
        <hr></hr>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Homework Uploads Coming Soon... (very soon)
          </DialogContentText>
        

        
        </DialogContent>
        
        <DialogActions>
          <Button uiMode="light" sx={{margin:"1rem"}}  variant="contained" onClick={handleCancel} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>


      {/* <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth

        PaperProps={{
          style: {
            marginLeft: "18%",
          },
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'transparent', // This makes the backdrop transparent
          },
        }}

      >
        <DialogTitle color="primary">Upload a New Course</DialogTitle>
        <hr></hr>
        <DialogContent>
          <div style={{
            // color: "lightgrey"

          }}>
            <TextField
              required
              fullWidth
              name="title"
              label="Coure Name"
              id="title"
              onChange={handleTitleChange}
              sx={{
                mb: "1rem"
              }}

            ></TextField>


            <DragDropFile maxFiles={3} onFilesDrop={handleFilesDrop}></DragDropFile>

            <LoadingOverlay isLoading={uploading} />



          </div>

        </DialogContent>
        <DialogActions sx={{ mb: "1rem", mr: "1rem" }}>
          <Button disabled={uploading} style={{ color: "#E05D13" }} onClick={handleCancel}>Cancel</Button>
          <Button disabled={uploading} style={{ backgroundColor: '#E05D13' }} variant="contained" onClick={handleSubmit} >Upload</Button>
        </DialogActions>

      </Dialog> */}

    </div>


  );
}




export default AddNewCourseDialog