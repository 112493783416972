import * as React from 'react';
import { Button, DigitTextField } from '../components/CustomMUIComponents';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppBar, Container, IconButton, InputAdornment } from '@mui/material';
import { useEffect, useState } from 'react';
import { supabase } from '../lib/supabaseClient';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ReactComponent as DigitIcon } from "../assets/DigitLogo.svg"
import "@fontsource/source-sans-pro"
import ErrorIcon from '@mui/icons-material/Error';
import "./styles/MuiStylesOverride.css"


export default function RecoverPassword() {
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [errorMessage, setErrorMessage] = useState("")
  const [requestSent, setRequestSent] = useState(false);

  const isFormValid = (): boolean => {
    if (password === "" || !passwordsMatch) {
      setPasswordsMatch(false)
    }
    return (passwordsMatch)
  }

  const handleClickShowPassword = () => {

    setShowPassword(!showPassword)
  }

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();


      if (isFormValid()) {
          const { error } = await supabase.auth.updateUser({password});

          if (error) {
              // TODO handle error messages from supabase
              switch (error.message) {
              default:
                  // Handle other errors
                  console.error('An error occurred:', error.message);
                  setErrorMessage(`${error.message}`)
              }
              return null
          } else {
              setRequestSent(true);
          }

          navigate("../login", { replace: false })
          setErrorMessage("")
      };
  }

  useEffect(() => {
    if (password && confirmPassword) {
      setPasswordsMatch(password === confirmPassword)
    }

  }, [password, confirmPassword])

  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: "#49454F"
      },
      secondary: {
        main: "#FFFFFF"
      },
    },
  })

  return (
    <Box gap={"2rem"} style={{ backgroundColor: "#FFFFFF" }} display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" >
      <AppBar elevation={0} position="static" sx={{ backgroundColor: "white" }}>
        <DigitIcon  onClick={() => navigate("../")} style={{
          flexGrow: 1,
          color: '#1232A0',
          marginTop: "0.5rem",
          marginLeft: '2rem',
          fontFamily: 'Poppins',
        }} />
      </AppBar>

      <Box width="100%" sx={{ paddingTop: "7rem", paddingBottom: "2rem", paddingLeft: "1rem", paddingRight: "1rem" }}>
        <ThemeProvider theme={defaultTheme}>
          <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box
              width="100%"
              gap="1rem"
              sx={{
                marginTop: "1rem",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: "center",
              }}
            >
              <Typography color="primary" component="h2" variant="h5" fontSize={"2.8125rem;"} fontFamily={"Poppins"} style={{ textAlign: "center" }}>
                Recover Your Password
              </Typography>
              <Typography style={{color: "#ed6c02"}} component="h1" variant="h5" fontSize={"1rem;"} fontFamily={"Source Sans Pro"}>
                {errorMessage !== "" && <IconButton edge="end" sx={{ mr: "0.25rem" }}>
                  <ErrorIcon color='warning' />

                </IconButton>}{errorMessage}
              </Typography>
              <Box autoComplete="off" gap="1rem" display="flex" flexDirection="column" alignItems="start" component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 2, width: '100%' }}>
                <DigitTextField
                  isInputValid={passwordsMatch}
                  required
                  fullWidth
                  name="password"
                  label="New Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  helperText={!passwordsMatch && "Passwords don't match."}
                  autoComplete="current-password"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setPassword(event.target.value)
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleClickShowPassword}
                        edge="end">
                        <div >
                          {!passwordsMatch ? <ErrorIcon color='warning' sx={{ mr: "0.25rem" }} /> :
                            showPassword ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}

                        </div>
                      </IconButton>

                    </InputAdornment>
                  }}
                />
                <DigitTextField
                  isInputValid={passwordsMatch}
                  required
                  fullWidth
                  name="confirm_password"
                  label="Confirm New Password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  autoComplete="current-password"
                  helperText={!passwordsMatch && "Passwords don't match."}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setConfirmPassword(event.target.value)
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleClickShowConfirmPassword}
                        edge="end">
                        <div >
                          {!passwordsMatch ? <ErrorIcon color='warning' sx={{ mr: "0.25rem" }} /> :
                            showConfirmPassword ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
                        </div>

                      </IconButton>

                    </InputAdornment>
                  }}
                />

                <Box display="flex" flexDirection="column" alignSelf="center">
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{ height: "3.25rem", width: "13rem", fontSize: "1.1rem" }}
                        disabled={requestSent}
                    >
                    Confirm
                    </Button>
                  </Box>
                </Box>
              </Box>
              {requestSent && <Typography>Your password has been updated!</Typography>}
            </Box>
          </Container>
        </ThemeProvider>
      </Box>
    </Box>
  );
}