import { Box, Divider, IconButton, List, ListItem, ListItemButton, ListItemText, Menu, MenuItem } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { Button } from "./CustomMUIComponents";
import { User } from "@supabase/supabase-js";
import { CircularProgress, ThemeProvider, createTheme } from "@mui/material";
import { Manager } from "../lib/manager";
import { Conversation, Thread } from "../lib/types";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useSnackbar } from './SnackbarContext';
import { useMediaQuery } from 'react-responsive';

const theme = createTheme({
  palette: {
    primary: {
      main: "#462ED8"

    },
    secondary: {
      main: "#FFFFFF"
    },
    warning: {
      main: "#FB754D"
    },
    error: {
      main: "#FB754D"
    }
  },
});


interface ChatHistoryListProps {
  user: User | null;
  fetchThreads: () => Promise<Thread[] | null>
  threadsLoading: boolean
  threads: Thread[]
  selectThread: (conversationId: string) => void
  resetChat: () => void
  currThreadId: string
  handleDrawerClose: () => void


}

const ChatHistoryList: FunctionComponent<ChatHistoryListProps & { setThreads: React.Dispatch<React.SetStateAction<Thread[]>> }> = ({ user, fetchThreads, threadsLoading, threads, selectThread, resetChat, setThreads, currThreadId, handleDrawerClose }) => {

  const manager = new Manager(process.env.REACT_APP_API_BASE_URL)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedThreadId, setSelectedThreadId] = useState<string | null>(null);
  const { showSnackbar } = useSnackbar();
  const isMobile = useMediaQuery({ maxWidth: 600 });



  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedThreadId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    // Add your delete logic here using selectedConversationId
    if (selectedThreadId) {
      manager.deleteThread(selectedThreadId)
      setThreads(prevThreads => prevThreads.filter(thread => thread.thread_id !== selectedThreadId));
      if (currThreadId === selectedThreadId) {
        resetChat()
      }
    } else {
      //show toast that delete failed
      showSnackbar("Delete failed", "error")

    }

    handleClose();
  };


  useEffect(() => {
    if (threads.length === 0) {
      fetchThreads().then(threads => {
        if (threads) {
          setThreads(threads)
        }
      })
    }
  })




  return (
    <Box width="100%" display="flex" flexDirection="column" mt="1rem">

      <div style={{
        fontFamily: "Poppins",
        color: "white",
        textAlign: "center",
        width: "100%",
        whiteSpace: 'normal'
      }}>

        <Box p="1rem">
          <Button uiMode="dark" variant="outlined" onClick={() => {
            resetChat()
          }}>
            New Chat
          </Button>
        </Box>

        {/* Scrollable List */}
        {threadsLoading ? (
          <Box flexGrow={1} display="flex" justifyContent="center" alignItems="center">
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <Box flexGrow={1} overflow="auto">
            <List>
              {threads.map(thread => (
                <>

                  <ListItem key={thread.thread_id} disablePadding>
                    <ListItemButton onClick={() => {

                      selectThread(thread.thread_id)
                      if (isMobile) {
                        handleDrawerClose()
                      }


                    }
                    }>
                      <ListItemText primary={thread.name} primaryTypographyProps={{ fontFamily: "Poppins" }} />
                    </ListItemButton>
                    <IconButton onClick={(e) => handleClick(e, thread.thread_id)}>
                      <MoreHorizIcon color="secondary" />
                    </IconButton>
                  </ListItem>
                  <Divider />
                </>
              ))}
            </List>
          </Box>
        )}
        <Menu
          sx={{
            height: "auto",
          }}
          id="conversation-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
      </div>


    </Box>)
}

export default ChatHistoryList;