import { FunctionComponent } from "react";
import AddNewCourseDialog from "./NewCourseDialog";
import { useSnackbar } from "../components/SnackbarContext";

interface CoursesProps {

}

const Courses: FunctionComponent<CoursesProps> = () => {
  const { showSnackbar } = useSnackbar();
  
  return (<div style={{
    backgroundColor: "#EFEDFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
    justifyContent: "center",
    position: 'fixed',
    width: "inherit",
    height: "inherit",
    borderBottomLeftRadius: "inherit",

  }}>
   
    <AddNewCourseDialog label={"Upload Homework"} handleShowSnack={function (success: boolean, message: string): void {
       if (success) {
        showSnackbar(message, "success")
  
      } else {
        showSnackbar(message, "error")
  
      }
    } }></AddNewCourseDialog>



  </div>);
}

export default Courses;