import katex from 'katex';

export function convertLatexToHTMLForDigit(latexText: string): string {
  try {
    // Use KaTeX to render the LaTeX expression
    const htmlText = katex.renderToString(latexText);
    return htmlText;
  } catch (error) {
    console.error(`KaTeX Error: ${error}`);
    return latexText; // Return the original LaTeX if KaTeX encounters an error
  }
}

export function convertLatexToHTMLForUser(input: string): string {
  const regex = /@+([^@]+)@+/g;
  try {
    let result = input.replace(/\n/g, "<br>");
    result = result.replace(regex, (match, capturedText) => {
      try {
        const formattedText = convertLatexToHTMLForDigit(capturedText);
        return formattedText;
      } catch (e) {
        console.error(`Error while converting LaTeX to HTML: ${e}`);
        return match; // Return the original LaTeX text if conversion fails
      }
    });

    return result;

  } catch (e) {
    console.error(e)
    return input
  }

  // try {
  //   // Debugging added logic to handle \text{} commands
  //   if (latexText.includes("\\text{")) {
  //     // Split the LaTeX content on \text{} commands and render separately
  //     const parts = latexText.split(/(\\text{[^}]+})/);
  //     return parts.map(part => {
  //       if (part.startsWith('\\text{')) {
  //         // Extract text content
  //         let textContent = part.slice(6, -1)
  //         textContent = textContent.replace(/\\/g, '');
  //         textContent = textContent.replace('textasteriskcentered', '*'); // Handle asterisks
  //         textContent = textContent.replace('textasciicircum', '^'); // Handle carets
  //         textContent = textContent.replace('textasciitilde', '~'); // Handle tildes
  //         textContent = textContent.replace('lbrack', '['); // Handle brackets
  //         textContent = textContent.replace('rbrack', ']'); // Handle brakets
  //         textContent = textContent.replace('textbraceleft', '{'); // Handle braces
  //         textContent = textContent.replace('textbraceright', '}'); // Handle braces
  //         return textContent;
  //       } else {
  //         // Render LaTeX parts
  //         return katex.renderToString(part, { throwOnError: false });
  //       }
  //     }).join('');
  //   } else {
  //     // Render the full LaTeX string if no text command is present
  //     return katex.renderToString(latexText, { throwOnError: false });
  //   }
  // } catch (error) {
  //   console.error(`KaTeX Error: ${error}`);
  //   return latexText; // Return the original LaTeX if KaTeX encounters an error
  // }
}

export const isValidFileType = (fileType: string): boolean => {
  const validFileTypes = ["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "text/plain"]

  return validFileTypes.includes(fileType)

}